import React from 'react'
import './mobile-head-bar.styles.scss';
import eln_icon from '@/assets/eln-logo-white.png'

const MobileHeadBar = () => {
    return (
        <div className='mobile-head-bar'>
            <div className='mobile-head-section'>
                <div className='mobile-head-icon-div'>
                    <img src={eln_icon} className='mobile-head-icon' />
                </div>
                IPA MC Portal
            </div>
        </div>
    )
}

export default MobileHeadBar