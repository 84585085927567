import React, { useEffect, useRef, useState } from 'react'
import './profile.styles.scss'

import ProfileIcon from './profile-icon.component'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { setUserProfile } from '@/state/slices/userSlice';

import logo_long from '@/assets/mc-full-title.png';
import { ModalAlert } from '@/components/SHARED/modal/modal.component';
import { states } from './states';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { getRandomLinearGradient } from '@/utils/randomGradient';

const ProfilePage = () => {

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const dataRef = useRef();
    const storage = getStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (userProfile) {
            console.log(userProfile)
            dataRef.current = userProfile
            setFormData(dataRef.current)
        }
    }, [userProfile])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    function saveBio(text) {
        const length = text.length;
        const count = document.getElementById('word-count')
        if (length <= 200) {
            count.innerHTML = `${length}/200`   
            saveData('bio', text)
        }
    }

    async function handleSubmit() {
        const reqs = ['fName', 'lName', 'org', 'state']
        for (let r of reqs) {
            if (!formData[r]) {
                setAlertMessage('Please complete the form before saving your profile.');
                return;
            }
        }
        setLoading(true)
		if (!formData.icon) {
            saveProfile(null);
        } else if (formData.icon.upload && formData.icon.type.indexOf('gif') == -1) {
			formData.icon.croppie.result({
				type:'blob',
				size: {'width': 212, 'height': 212},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(formData.icon,blob)
				return;
			})
		} else if (formData.icon.upload) {
			uploadImage(formData.icon, formData.icon.file)
			return;
		} else if (formData.icon.url) {
			saveProfile(formData.icon.url)
			return;
		} else if (formData.icon) {
			saveProfile(formData.icon)
		}
	}
				
	function uploadImage(profileIcon, blob) {		
		const now = new Date().getTime();
		const profileRef = ref(storage, `uploaded-profile-icons/${now}-${formData.icon.name}`)
		const metadata = {
			contentType: formData.icon.type,
            owner: userProfile.cst_key
		};
		uploadBytes(profileRef, blob, metadata).then((snapshot) => {
			getDownloadURL(profileRef)
			.then((url) => {
				saveProfile(url)
				return;
			})
		})
	}
				
	const saveProfile = async (url) => {
        console.log(formData);
        const now = new Date().getTime();
        const profile = {...formData, ...{
            name: `${formData.fName} ${formData.lName}`,
            ts: now,
            uid: currentUser.uid,
            icon: url,
            status: 'active',
            permiss_part: true,
        }}
        if (!profile.gradient) {
            profile.gradient = getRandomLinearGradient();
        }
        if (!profile.ts_joined) {
            profile.ts_joined = profile.ts;
        }
        console.log(profile);
        const res = await UseCloudFunction('updateProfile', {'profile': profile, 'cst_key': userProfile.cst_key})
        console.log(res);
        if (res.success) {
            setTimeout(() => {
                dispatch(setUserProfile(profile))
                // dispatch(setCurrentOrg(res.newOrg))
                // dispatch(setMyOrgs({[res.newOrg.orgId]: res.newOrg}))
                navigate('/home')
            }, 1000)
        } else {
            setAlertMessage('Something went wrong. Try again later.')
        }
        
        return;
	}

    return (
        <div className='profile-page'>
            <img src={logo_long} className='profile-logo' />
            <div className='g-card'>
                {/* <div className='card-head'>
                    <span>Create a Profile</span><hr />
                </div> */}
                <div className='g-grid'>
                    <div className="g-half-col">
                        <div className="field">
                            <label>First Name</label>
                            <input type='text' defaultValue={formData.fName ? formData.fName : ''} onChange={(e) => saveData('fName', e.target.value)} />
                        </div>
                    </div>
                    <div className="g-half-col">
                        <div className="field">
                            <label>Last Name</label>
                            <input type='text' defaultValue={formData.lName ? formData.lName : ''} onChange={(e) => saveData('lName', e.target.value)} />
                        </div>
                    </div>
                </div>
                
                <div className='g-grid'>
                    <div className="g-half-col">
                        <div className="field">
                            <label>Organization/District</label>
                            <input type='text' defaultValue={formData.org ? formData.org : ''} onChange={(e) => saveData('org', e.target.value)} />
                        </div>
                    </div>
                    <div className="g-half-col">
                        <div className="field">
                            <label>State</label>
                            <select value={formData.state ? formData.state : ''} onChange={(e) => saveData('state', e.target.value)}>
                                <option value='' disabled>Choose...</option>
                                {
                                    (states) &&
                                    states.map(s => (
                                        <option key={s.abbreviation} value={s.abbreviation}>{s.name}</option>
                                    ))
                                }

                            </select>
                        </div>
                    </div>
                </div>
                {
                    (formData.state === 'IL') &&
                    <div className="field">
                        <label>Illinois Educator Identification Number (IEIN)</label>
                        <input type='text' value={formData.iein ? formData.iein : ''} onChange={(e) => saveData('iein', e.target.value)} />
                    </div>
                }
                <ProfileIcon formData={formData} saveData={saveData} />
                <div className="g-space-20"></div>
                {/* <div className='field'>
                    <label>Tell us about you</label>
                    <textarea defaultValue={formData.bio ? formData.bio : ''} onChange={(e) => saveBio(e.target.value)}></textarea>
                    <div id='word-count'>0/200</div>
                </div> */}
                <hr />
                <div className='buttons'>
                    <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                    { 
                        (!loading)
                        ? 
                        <button className='g-button save-profile-button primary' onClick={() => handleSubmit()}>Save Your Profile</button>
                        :
                        <button className='g-button save-profile-button disabled' ><ThreeDotsLoader /></button>
                    }
                </div>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            {/* <LandingPage /> */}
        </div>
    )
}

export default ProfilePage