import React, { useEffect, useRef, useState } from 'react'
import './portfolio-pages.styles.scss';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import InstructionModal from './instruction-modal.component';
import EvidenceCard from '../../evidence-card/evidence-card.component';
import { format } from 'date-fns';

const PrecheckPortfolioPage = ({ mcData, mcProgress}) => {

    const [ showInstructions, setShowInstructions ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);
    const [ formData, setFormData ] = useState({});
    const [ evalData, setEvalData ] = useState(null);
    const dataRef = useRef({});
    


    useEffect(() => {
        if (!mcData || !mcProgress ) return;
        dataRef.current = mcProgress[`content_precheck`];
        setFormData(dataRef.current);
        setEvalData(mcProgress.eval_precheck);
        console.log(dataRef.current);
    }, [mcData, mcProgress])

    return (
        <div className='portfolio-page-container'>
            {
                (mcData && formData && formData.exp && formData.upload)
                ?
                <div>
                    <div className='title-box'>
                        <div className='section-title'><b>Precheck Upload and Self-Analysis</b></div>
                        {
                            (!formData.status || formData.status === 'saved') 
                            ?
                            <button className='g-button small-btn' onClick={() => setShowInstructions(true)}>Instructions</button>
                            :
                            <div className='g-list-item approved'>Approved!</div>
                        }
                    </div>
                    
                    
                    {/* <hr /> */}
                    <div className="g-space-2"></div>
                    <div className='portfolio-page-content'>
                        <div className='g-list-item'>
                            <EvidenceCard 
                                mcProgress={mcProgress} 
                                title='Evidence 1 (required)' 
                                id='ev1' 
                                hidden={false} 
                                disabled={disabled}
                                formData={dataRef.current} 
                                evNumber={0} 
                                // chooseEvidenceFromModal={chooseEvidenceFromModal}
                                // saveExplanation={saveExplanation} 
                                // removeEvidence={removeEvidence}
                                sectionId='precheck'
                                // saveDirtyStatus={saveDirtyStatus}
                            />
                            
                        </div>
                        <div className="g-space-2"></div>
                        <div className='g-list-item'>
                            <EvidenceCard 
                                mcProgress={mcProgress} 
                                title='Evidence 2 (optional)' 
                                id='ev2' 
                                hidden={!formData.exp || !formData.exp[1]} 
                                disabled={disabled}
                                formData={dataRef.current} 
                                evNumber={1} 
                                // chooseEvidenceFromModal={chooseEvidenceFromModal}
                                // saveExplanation={saveExplanation} 
                                // removeEvidence={removeEvidence}
                                sectionId='precheck'
                                // saveDirtyStatus={saveDirtyStatus}
                            />
                        </div>
                        <div className="g-space-2"></div>
                        <div className='g-list-item'>
                            <EvidenceCard 
                                mcProgress={mcProgress} 
                                title='Evidence 3 (optional)' 
                                id='ev3' 
                                hidden={!formData.exp || !formData.exp[2]} 
                                disabled={disabled}
                                formData={dataRef.current} 
                                evNumber={2} 
                                // chooseEvidenceFromModal={chooseEvidenceFromModal}
                                // saveExplanation={saveExplanation} 
                                // removeEvidence={removeEvidence}
                                sectionId='precheck'
                                // saveDirtyStatus={saveDirtyStatus}
                            />   
                        </div>
                        <div className="g-space-1"></div>
                    </div>
                    <div className="g-space-2"></div>
                    <div className='g-list-item prev-comment-item' >
                        <div className='title'>Evaluator's Comments:</div>
                        {
                            (evalData && evalData.prev_comments) &&
                            Object.values(evalData.prev_comments)
                            .sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
                            .map(e => (
                                <div key={e.timestamp}>
                                    <div className="g-space-1"></div>
                                    <div>{format(new Date(e.timestamp), 'Pp')}: <b>{e.comment}</b></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                :
                <div className='center-col'><ThreeDotsLoader /></div>
            }
            <InstructionModal show={showInstructions} cancel={() => setShowInstructions(false)} precheck={true} />
        </div>
    )
}

export default PrecheckPortfolioPage    
