import React from 'react'
import './user-icon.styles.scss'

const UserIcon = ({ profile, reverse, large, medium }) => {
    return (
        <div className={`user-icon-div ${reverse ? 'reverse' : ''}`}>
            {
                (profile.icon) 
                ?
                <img src={profile.icon} className={`user-icon ${large ? 'large' : medium ? 'medium' : ''}`} alt="profile-pic"  referrerPolicy="no-referrer" />
                :
                <div className={`user-icon ${large ? 'large' : medium ? 'medium' : ''}`}><div className='grad' style={{'background': profile.gradient}}>{profile.fName.toUpperCase()[0]}</div></div>
            }
        </div>
    )
}

export default UserIcon