import React, { useEffect, useState } from 'react'
import './summary-portfolio.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { properCase } from '@/utils/properCase';
import { Link, useNavigate } from 'react-router-dom';
import { checkPortfolioStatus } from '../get-portfolio-status';

const SummaryPortfolioPage = ({ mcData, mcProgress, content, dirtyForms }) => {

    const [ storedProgress, setStoredProgress ] = useState({});
    const navigate = useNavigate();

    function goTo(path) {
        navigate(path)
    }

    useEffect(() => {
        if (!mcData || !mcProgress) return;
        // console.log(mcProgress);
        let storedData = {}
        for (let k of Object.keys(mcProgress)) {
            if (k.includes('content_')) {
                const id = k.split('_')[1];
                storedData[id] = JSON.parse(window.localStorage.getItem(`${id}_${mcData.mc_key}`))
            }
        }
        // console.log(storedData);
        setStoredProgress(storedData);
    }, [mcData, mcProgress])

    return (
        <div className='portfolio-summary-page'>
            <button className='g-button text-only card-head' onClick={() => goTo(`/microcredential/${mcData.mc_key}/portfolio`)}>
                Overall Summary
            </button>
            <div className="g-space-2"></div>
            <div className='portfolio-summary-content'>
            {
                (content) &&
                content.sort((a,b) => a.num > b.num ? 1 : -1)
                .map(e => {
                    if (e.comp) {
                        return (
                            <div key={`${e.id}-summary`} className='outcome-summary'>
                                <div className='title'>{e.name}</div>
                                {
                                    (e.comp) &&
                                    Object.values(e.comp).sort((a,b)  => a.id > b.id ? 1 : -1)
                                    .map(c => (
                                        <div key={c.id} className='category'>
                                            <Link to={c.id}>
                                                <div className='title-box'>
                                                    <div className='status'>
                                                        <FontAwesomeIcon 
                                                            icon={checkPortfolioStatus(c.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                                                            className={checkPortfolioStatus(c.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                                                            itle={checkPortfolioStatus(c.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).title} 
                                                        />
                                                    </div>
                                                
                                                    <div className='text'>
                                                        <b>{c.id}: </b>{c.desc}
                                                    </div>
                                                </div>
                                            </Link>
                                            <button className='g-button small-btn' onClick={() => goTo(`/microcredential/${mcData.mc_key}/portfolio/${c.id}`)}>Go</button>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    } else if (e.id === 'materials') {
                        return (
                            <div key={e.id} className='category'>
                                <div className='status'>
                                    <FontAwesomeIcon 
                                        icon={checkPortfolioStatus(e.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                                        className={checkPortfolioStatus(e.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                                        title={checkPortfolioStatus(e.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).title} 
                                    />
                                </div>
                                <div className='text bold'>
                                    {e.name}
                                </div>
                                
                            </div>
                        )
                    } else {
                        return (
                            <div key={e.id} className='category'>
                                <Link to={e.id}>
                                    <div className='title-box'>
                                        <div className='status'>
                                            <FontAwesomeIcon 
                                                icon={checkPortfolioStatus(e.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                                                className={checkPortfolioStatus(e.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                                                title={checkPortfolioStatus(e.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).title} 
                                            />
                                        </div>
                                        <div className='text bold'>
                                            {e.name}
                                        </div>
                                    </div>
                                </Link>
                                <button className='g-button small-btn' onClick={() => goTo(`/microcredential/${mcData.mc_key}/portfolio/${e.id}`)}>Go</button>
                            </div>
                        )
                    }
                })
            }
            </div>
        </div>
    )
}

export default SummaryPortfolioPage