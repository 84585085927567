import { compareArrays } from "./compareArrays";

export function checkForChanges(progress, formData, details) {
    let stillMatch = [];
    if (!progress.declinedAttempts) {
        return stillMatch;
    }
    let currentData = progress;
    if (formData) {
        currentData = formData;
    }
    for (let detailId of details) {
        const prevAttempt = Object.values(progress.declinedAttempts)
            .filter(att => att.id === detailId)
            .sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)[0];
        console.log(prevAttempt);
        console.log(currentData); // FIX THIS STUFF
        const newData = currentData[`content_${detailId}`]
        const expsAreMatching = compareArrays(newData.exp, prevAttempt.exp);
        const uploadsAreMatching = compareArrays(newData.upload, prevAttempt.upload);
        console.log(expsAreMatching, uploadsAreMatching);
        if (expsAreMatching && uploadsAreMatching) {
            stillMatch.push(detailId);
        }
    }
    return stillMatch;
}