import React, { useEffect, useRef, useState } from 'react'
import './mc-page.styles.scss';
import { Route, Routes, useFetcher, useLocation, useNavigate, useParams } from 'react-router-dom';
import HeadBar from '@/components/layout-auth/head-bar/head-bar.component';
import BackButton from '@/components/back-button/back-button.component';
import McProgressContainer from '@/components/HOME/mc-pages/mc-progress/mc-progress-container.component';
import { useDispatch, useSelector } from 'react-redux';
import { getMcDataFromDb, getProgressFromDb, getUniqueDocFromDb } from '@/utils/getDataFromDb';
import MaterialsPage from '@/components/HOME/mc-pages/materials-page/materials-page.component';
import { setMyMcList } from '@/state/slices/contentSlice';
import McPrecheckPage from '@/components/HOME/mc-pages/mc-precheck/mc-precheck-page.component';
import McPortfolioPage from '@/components/HOME/mc-pages/mc-portfolio/mc-portfolio-page.component';

const McPage = ({}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const myMcList = useSelector(state => state.contentData.myMcList);
    const { mc_key } = useParams();
    const [ mcData, setMcData ] = useState(null);
    const [ mcProgress, setMcProgress ] = useState(null);
    const [ progressNumber, setProgressNumber ] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let mcListener;
    let progressListener;
    let progNumListener;

    useEffect(() => {

        return () => {
            if (mcListener) mcListener();
            if (progressListener) progressListener();
            if (progNumListener) progNumListener();
        }
    }, [])

    useEffect(() => {
        if (!mc_key || !userProfile) {return;}
        console.log('getting data from db')
        getMcData();
        getProgress();
        getProgressNumber();
    }, [mc_key, userProfile])

    function cancel() {
        if (location.pathname === `/microcredential/${mc_key}`) {
            navigate('/home');
        } else {
            navigate(`/microcredential/${mc_key}`);
        }
    }

    function getMcData() {
        getMcDataFromDb({'mc_key': mc_key, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (!data) {
                navigate('/home')
            }
            setMcData(data);
            dispatch(setMyMcList({...myMcList, ...{[data.mc_key]: data}}))
        }
        function handleListener(unsub) {
            mcListener = unsub;
        }
    }

    function getProgress() {
        getProgressFromDb({'mc_key': mc_key, 'cst_key': userProfile.cst_key, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            console.log(data);
            setMcProgress(data);
        }
        function handleListener(unsub) {
            progressListener = unsub;
        }
    }

    function getProgressNumber() {
        getUniqueDocFromDb({"path": `progressNumbers/${mc_key}`, "callback": callback, "handleListener": handleListener})
        function callback(data) {
            if (data[userProfile.cst_key]) {
                setProgressNumber(data[userProfile.cst_key]);
            }
        }
        function handleListener(unsub) {
            progNumListener = unsub;
        }

    }

    return (
        <div className='mc-page'>
            <HeadBar />
            <div className='mc-page-container'>
                <div className='mc-page-content'>
                    <BackButton cancel={cancel} />
                    <div className="g-space-20"></div>
                    <Routes>
                        <Route path='' element={<McProgressContainer mcData={mcData} mcProgress={mcProgress} progressNumber={progressNumber} />}></Route>
                        <Route path='materials' element={<MaterialsPage mcData={mcData} mcProgress={mcProgress} />}></Route>
                        <Route path='precheck' element={<McPrecheckPage mcData={mcData} mcProgress={mcProgress} />}></Route>
                        <Route path='portfolio' element={<McPortfolioPage mcData={mcData} mcProgress={mcProgress} />}></Route>
                        <Route path='portfolio/:sectionId' element={<McPortfolioPage mcData={mcData} mcProgress={mcProgress} />}></Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default McPage