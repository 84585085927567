import React from 'react'
import './back-button.styles.scss';
import { faArrowLeft, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackButton = ({ cancel, x }) => {
    return (
        <button className='back-button g-button text-only' onClick={() => cancel()}>
            {
                (x) 
                ?
                <FontAwesomeIcon icon={faX} />
                : 
                <><FontAwesomeIcon icon={faArrowLeft} />Back</>
            }
        </button>
    )
}

export default BackButton