import React, { useEffect, useState } from 'react'
import './changelog.styles.scss';
import text from '@/changelog.txt';
import HeadBar from '@/components/layout-auth/head-bar/head-bar.component';

const ChangeLog = () => {
    
    const [ displayText, setDisplayText ] = useState(null);

    useEffect(() => {
        fetch(text)
        .then(r => r.text())
        .then(transformed => {
            setDisplayText(transformed);
        })
    }, [])

    return (
        <div className='changelog'>
            <HeadBar />
            <div className='changelog-content'>
                <div className='card-container'>
                    <div className='g-card'>
                        <h3>Change Log</h3>
                        {displayText}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeLog    