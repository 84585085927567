import React, { useEffect, useState } from 'react'
import { switchUserButtons } from './admin-users.component'
import { useDispatch, useSelector } from 'react-redux'
import { setOldUsers } from '@/state/slices/contentSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useNavigate } from 'react-router-dom';

const ImportUsers = ({ oldUsers, importedUsers }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ refreshing, setRefreshing ] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        switchUserButtons('import')
    }, [])

    return (
        <div className='import-users'>
            <div className='section-title with-icon'>
                Import Users
                {/* <FontAwesomeIcon icon={faRefresh} className={`refresh-icon ${refreshing && 'spinning-icon'}`} onClick={() => getOldUsers()} /> */}
            </div>
            <table className='g-table'>
                <thead>
                    <tr>
                        <th width="45%">Name</th>
                        <th>Email</th>
                        <th>Evaluator</th>
                        <th>Enrolled</th>
                        <th className='icon-td'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (oldUsers) &&
                        Object.values(oldUsers).sort((a,b) => {
                            if (a.lName && b.lName) {
                                if (a.lName.toLowerCase() > b.lName.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            } 
                            else if (a.lName && !b.lName) {
                                if (a.lName.toLowerCase() > b.email.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }
                            else if (b.lName && !a.lName) {
                                if (a.email.toLowerCase() > b.lName.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }
                            else if (a.email.toLowerCase() > b.email.toLowerCase()) {
                                return 1;
                            } 
                            else {
                                return -1;
                            }
                        })
                        .filter(u => !importedUsers.list.includes(u.email))
                        .map((u) => (
                            <tr key={u.email}>
                                <td>{u.fName ? `${u.lName}, ${u.fName}` : u.email}</td>
                                <td>{u.email}</td>
                                <td>{u.courses ? Object.values(u.courses).filter(c => c.evals === true).length : 0}</td>
                                <td>{u.courses ? Object.values(u.courses).filter(c => c.parts === true).length : 0}</td>
                                <td><button className='g-button small-btn' onClick={() => navigate(`/admin/importuser/${u.id}`)}>Import</button></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ImportUsers