import React, { useEffect, useState } from 'react'
import './side-menu.styles.scss'

import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faChartPie, faGear, faMouse, faUser } from '@fortawesome/free-solid-svg-icons';
import { setReqTab } from './toggle-items';

const SideMenuUser = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [ showReqDrop, setShowReqDrop ] = useState(false)

    useEffect(() => {
        if (location.pathname.includes('req-')) {
            setShowReqDrop(true);
        } else {
            setShowReqDrop(false);
            setReqTab();
        }
    }, [location.pathname])


    return (
        <div className="admin-menu-container">
            <div className={`side-menu-item ${location.pathname == '/admin' && 'active'}`} onClick={() => navigate('/admin')}>
                <div className='menu-item-icon'>
                    <FontAwesomeIcon icon={faCertificate} size='xl' />
                </div>
                Manage MC's
            </div>
            <div className={`side-menu-item ${location.pathname.includes('/admin/users') && 'active'}`} onClick={() => navigate('users')}>
                <div className='menu-item-icon'>
                    <FontAwesomeIcon icon={faUser} size='xl' />
                </div>
                Manage Users
            </div>
            <div className={`side-menu-item ${location.pathname.includes('/admin/data') && 'active'}`} onClick={() => navigate('data')}>
                <div className='menu-item-icon'>
                    <FontAwesomeIcon icon={faChartPie} size='xl' />
                </div>
                Usage Data
            </div>
            
            <div className={`side-menu-item ${location.pathname.includes('/admin/settings') && 'active'}`} onClick={() => navigate('settings')}>
                <div className='menu-item-icon'>
                    <FontAwesomeIcon icon={faGear} size='xl' />
                </div>
                Settings
            </div>

            <div className={`side-menu-item ${location.pathname.includes('/admin/useractivity') && 'active'}`} onClick={() => navigate('useractivity')}>
                <div className='menu-item-icon'>
                    <FontAwesomeIcon icon={faMouse} size='xl' />
                </div>
                User Activity
            </div>
            
        </div>
    )
}

export default SideMenuUser