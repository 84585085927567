import React, { useEffect, useState } from 'react'
import './eval-summary.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkStatus } from '../determineStatus';
import { Link, useNavigate } from 'react-router-dom';
import { properCase } from '@/utils/properCase';

const EvalSummary = ({ progress, currentData, closeModal, content, dirtyStatus }) => {

    const navigate = useNavigate();

    function goTo(path) {
        if (closeModal) closeModal();
        navigate(path);
    }

    return (
        <div className='eval-summary-page'>
            <button className='g-button text-only card-head' onClick={() => goTo(`/evaluate/${currentData.mcData.mc_key}`)}>
                Overall Summary
            </button>
            <div className="g-space-2"></div>
            <div className='eval-summary-content'>
            {
                (content) &&
                content.sort((a,b) => a.num > b.num ? 1 : -1)
                .map(e => {
                    if (e.comp) {
                        return (
                            <div key={`${e.id}-summary`} className='outcome-summary'>
                                <div className='title'>{e.name}</div>
                                {
                                    (e.comp) &&
                                    Object.values(e.comp).sort((a,b)  => a.id > b.id ? 1 : -1)
                                    .map(c => (
                                        <div key={c.id} className='category'>
                                            <Link to={c.id}>
                                                <div className='title-box'>
                                                    <div className='status'>
                                                        <FontAwesomeIcon icon={checkStatus(c.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).icon} className={checkStatus(c.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).class} title={checkStatus(c.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).title} />
                                                    </div>
                                                    <div className='text'>
                                                        <b>{c.id}: </b>{c.desc}
                                                    </div>
                                                </div>
                                            </Link>
                                            <button className='g-button small-btn' onClick={() => goTo(c.id)}>Go</button>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    } else if (e.id === 'materials') {
                        return (
                            <div key={e.id} className='category'>
                                <div className='status'>
                                    <FontAwesomeIcon icon={checkStatus(e.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).icon} className={checkStatus(e.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).class} title={checkStatus(e.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).title} />
                                </div>
                                <div className='text bold'>
                                    {e.name}
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={e.id}>
                                <div className='category'>
                                    <Link to={e.id}>
                                        <div className='title-box'>
                                            <div className='status'>
                                                <FontAwesomeIcon icon={checkStatus(e.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).icon} className={checkStatus(e.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).class} title={checkStatus(e.id, {'progress': progress, 'currentData': currentData, 'dirtyStatus': dirtyStatus}).title} />
                                            </div>
                                            <div className='text bold'>
                                                {e.name}
                                            </div>
                                        </div>
                                    </Link>
                                    <button className='g-button small-btn' onClick={() => goTo(e.id)}>Go</button>
                                </div>
                            </div>
                        )
                    }
                })
            }
            </div>
            {/* {
                (currentData) &&
                <div className='eval-summary-content'>
                    <div className='category'>
                        <div className='status'>
                            <FontAwesomeIcon icon={checkStatus('materials', currentData).icon} className={checkStatus('materials', currentData).class} title={properCase(checkStatus('materials', currentData).class)} />
                        </div>
                        <div className='text'>
                            Viewed Required Micro-Credential Materials
                        </div>
                    </div>
                    <div className='category'>
                        <Link to='precheck'>
                            <div className='title-box'>
                                <div className='status'>
                                    <FontAwesomeIcon icon={checkStatus('precheck', currentData).icon} className={checkStatus('precheck', currentData).class} title={properCase(checkStatus('precheck', currentData).class)} />
                                </div>
                                <div className='text'>
                                    Precheck Artifact and Self-Analysis
                                </div>
                            </div>
                        </Link>
                        <button className='g-button small-btn' onClick={() => goTo('precheck')}>Go</button>
                    </div>
                    <div className='category'>
                        <div className='status'>
                            <FontAwesomeIcon icon={checkStatus('overview', currentData).icon} className={checkStatus('overview', currentData).class} title={properCase(checkStatus('overview', currentData).class)} />
                        </div>
                        <div className='text'>
                            Overview Questions
                        </div>
                        <button className='g-button small-btn' onClick={() => goTo('overview')}>Go</button>
                    </div>
                    {
                        (currentData && currentData.mcData.content) &&
                        Object.values(currentData.mcData.content).sort((a,b) => a.num > b.num ? 1 : -1)
                        .map(e => (
                            <div key={`${e.id}-summary`} className='outcome-summary'>
                                <div className='title'>{e.name}</div>
                                {
                                    (e.comp) &&
                                    Object.values(e.comp).sort((a,b)  => a.id > b.id ? 1 : -1)
                                    .map(c => (
                                        <div key={c.id} className='category'>
                                            <div className='status'>
                                                <FontAwesomeIcon icon={checkStatus(c.id, currentData).icon} className={checkStatus(c.id, currentData).class} title={properCase(checkStatus(c.id, currentData).class)} />
                                            </div>
                                            <div className='text'>
                                                <b>{c.id}: </b>{c.desc}
                                            </div>
                                            <button className='g-button small-btn'>Go</button>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        
                    }
                </div>
            } */}
        </div>
    )
}

export default EvalSummary