import React, { useEffect } from 'react'
import './create-mc-form-container.styles.scss'
import CreateMcForm from './create-mc-form.component'
import { switchMenu } from '@/components/layout-auth/sidebar-left/sidebar-left.component'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from '@/components/back-button/back-button.component'

const CreateMcFormContainer = ({ users, mcList, convert }) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        switchMenu('manage-mcs')
        document.getElementById('scroll-point').scrollIntoView({'behavior': 'smooth', 'top': '-80'});
        // document.getElementsByClassName('main-content')[0].scrollTo({'behavior': 'smooth', 'top': '70px'});
    }, [])

    useEffect(() => {
        // document.getElementById('scroll-point').scrollIntoView({'behavior': 'smooth', 'top': '70px'});
    }, [location])

    function cancel() {
        navigate(-1)
    }

    return (
        <div className='create-mc-form-container'>
            <div className='g-space-20'></div>
            <BackButton cancel={cancel} />
            <div className='g-space-20'></div>
            <div className='section-title'>Create/Edit a Micro-Credential</div>
            <hr />
            <CreateMcForm 
                users={users} 
                mcList={mcList} 
                cancel={cancel} 
                convert={convert} 
            />
        </div>
    )
}

export default CreateMcFormContainer