import Modal from '@/components/SHARED/modal/modal.component'
import React from 'react'

const InstructionModal = ({ show, cancel, precheck }) => {
  return (
    <div>
        <Modal show={show} cancel={cancel} closeButton={true} text='Skill Demonstration Product and Self-Analysis Instructions' cls='wide'>
            {
                (precheck)
                ?
                <div className='instructions'>
                    <p><b>Purpose:</b> The purpose of the precheck is to provide the participant practice demonstrating his/her ability to upload and analyze an artifact that demonstrates detail competency for one of the micro-credential outcomes. This allows the participant to receive feedback from the assessor to ensure understanding and increase the probability of success prior to submission of the participant's complete portfolio.</p>
                    <p>The participant can choose any detail from any of the micro-credential's essential outcomes for this “trial run.” The participant will receive feedback on both the quality of the evidence submitted as well as an analysis of whether he/she met the expectations of artifact self-analysis. Further, the assessor will determine if the artifact and self-analysis successfully demonstrated proficiency of the outcome's detail.</p>
                    <p>This important step sets the participant up for greater success when submitting the full artifact portfolio.</p>
                    <p>If the precheck submission does not initially demonstrate the expected level of competency, the participant will be allowed to revise and re-upload a modified artifact and/or self-analysis explanation.</p>
                    <p>Once the assessor approves the precheck artifact and self-analysis, the outcome's detail will count toward the full portfolio upload and submission, ensuring the participant does not have to re-upload and re-analyze the same detail.</p>
                    <p>If further guidance is needed, refer to the training materials.</p>
                    <hr />
                    <ol>
                        <li>Choose an essential outcome detail for which you will provide evidence.</li>
                        <li>Upload an online artifact.<br />
                        <b>NOTE:</b> Participants will be allowed to upload a maximum of three artifacts to demonstrate proficiency.</li>
                        <li>Give the uploaded artifact a short name for future reference.<br />
                        <b>NOTE:</b> Uploaded files have a 30mb size limit.</li>
                        <li>Indicate the exact location of the evidence within the artifact. (video time stamp, page number, paragraph number, line number, etc.) Complete a self-analysis narrative explaining how this artifact demonstrates your personal proficiency of the selected detail.</li>
                        <li>Upon completion, click the "Submit" button below.<br />
                        <b>NOTE:</b> Once you have submitted your pre-check evidence and analysis, this section will show as “disabled Approval,” and it will not be accessible until it has been reviewed.</li>
                    </ol>
                </div>
                :
                <div className='instructions'>
                    <p>For each detail listed under each Essential Outcome, participants will be allowed to upload a maximum of three artifacts to demonstrate proficiency.</p>
                    <ol>
                        <li>Upload or include a link to an online artifact(s).</li>
                        <li>Give the uploaded or linked artifact(s) a short name for future reference.<br />
                        <b>NOTE:</b> Artifacts can be uploaded at any point in the process. They can be accessed by clicking “View Uploads.”</li>
                        <li>Click on the Evidence links 1, 2, and/or 3 and choose artifact.</li>
                        <li>Indicate the exact location of the evidence within the artifact. (video time stamp, page number, paragraph number, line number, etc.). Complete a self-analysis narrative explaining how this artifact demonstrates your personal proficiency of the detail.</li>
                        <li>Upon completion of each artifact upload and analysis, click the <b>"Save Evidence, Location, and Analysis”</b> button below.</li>
                        <li>You will complete steps 1-5 for all details.</li>
                    </ol>
                    <p><b>Scoring – Proficient/Does Not Meet</b></p>
                    <p>A rating of Proficient for each essential outcome is required to obtain the micro-credential. The practitioner must clearly and compellingly demonstrate proficiency of all details under each essential outcome.</p>
                </div>
            }
        </Modal>
    </div>
  )
}

export default InstructionModal