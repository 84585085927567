import React, { useEffect } from 'react'
import './admin-data.styles.scss'
import { toggleMenuItems } from '../admin-layout/side-menu/toggle-items'

const AdminData = ({
    users,
    sortCriteria,
    sortCriteriaLists 
}) => {

    return (
        <div>
            <h3>Data</h3>
        </div>
    )
}

export default AdminData