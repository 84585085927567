import React, { useEffect, useRef, useState } from 'react'
import './view-mc-data.styles.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BackButton from '@/components/back-button/back-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faMagnifyingGlass, faPen, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { getMcRegsFromDb } from '@/utils/getDataFromDb';
import Modal, { ModalConfirm } from '@/components/SHARED/modal/modal.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';

const ViewMcData = ({ mcList, regs, nfeRegs, users, progressNumbers }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ currentMc, setCurrentMc ] = useState(null);
    const [ partList, setPartList ] = useState(null);
    const [ completeList, setCompleteList ] = useState(null);
    const [ evalList, setEvalList ] = useState(null);
    const [ showUserChoiceModal, setShowUserChoiceModal ] = useState(false);
    const [ modalText, setModalText ] = useState(null);
    const [ partOrEval, setPartOrEval ] = useState(null);
    const [ filteredUsers, setFilteredUsers ] = useState(null);
    const [ usersToAdd, setUsersToAdd ] = useState([]);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ unaddedUsers, setUnaddedUsers ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const { mcId } = useParams();
    const navigate = useNavigate();
    const approveRef = useRef();
    const toAddRef = useRef();
    let regsListener;

    useEffect(() => {
        if (!users) {return;}
        setFilteredUsers(Object.values(users));
        toAddRef.current = [];
    }, [users])

    useEffect(() => {
        if (!mcId) {return;}
        setCurrentMc(mcList[mcId]);
        // console.log(mcList[mcId]);
    }, [mcId]);

    useEffect(() => {
        console.log(regs[mcId])
        console.log(users);
        console.log(nfeRegs);
        console.log(progressNumbers);
        if (!users || !mcId || !regs || !regs[mcId] || !nfeRegs || !nfeRegs[mcId] || !progressNumbers) return;
        console.log('getting stuff')
        let partObj = {};
        let completeObj = {};
        let evalObj = {};
        for (let cKey of Object.keys(regs[mcId].roles)) {
            if (!users[cKey]) continue;
            if (regs[mcId].roles[cKey].includes("part")) {
                if (progressNumbers[mcId] && progressNumbers[mcId][cKey] && progressNumbers[mcId][cKey].progNum >= 9) {
                    completeObj[cKey] = users[cKey];
                } else {
                    partObj[cKey] = users[cKey];
                }
            }
            if (regs[mcId].roles[cKey].includes("eval")) {
                evalObj[cKey] = users[cKey];
            }
        }
        setPartList(partObj);
        setCompleteList(completeObj);
        setEvalList(evalObj);
        console.log(evalObj);
    }, [users, regs, nfeRegs, mcId, progressNumbers])

    function cancel() {
        navigate(-1)
    }

    function launchAddUserModal(permiss) {
        setPartOrEval(permiss);        
        if (permiss.includes('part')) {
            setModalText('Choose a user to add to this Micro-Credential');
            const availableUsers = Object.values(users).filter(u => !partList[u.cst_key]);
            setUnaddedUsers(availableUsers)
            setFilteredUsers(availableUsers);
        } else {
            setModalText('Choose a user to evaluate this Micro-Credential');
            const availableUsers = Object.values(users).filter(u => !evalList[u.cst_key]);
            setUnaddedUsers(availableUsers)
            setFilteredUsers(availableUsers);
        }
        setShowUserChoiceModal(true);
        
    }

    function searchPeople(str) {
        console.log(str);
        const test = str.toLowerCase();
        setFilteredUsers(unaddedUsers.filter(u => u.fName.toLowerCase().includes(test) || u.lName.toLowerCase().includes(test) || u.email.toLowerCase().includes(test)))
    }

    function toggleUser(cst_key, role) {
        let tempUsers = [...toAddRef.current];
        const el = document.getElementById(cst_key);
        el.classList.toggle('active');
        if (tempUsers.includes(cst_key)) {
            tempUsers.splice(tempUsers.indexOf(cst_key), 1);
        } else {
            tempUsers.push(cst_key);
        }
        toAddRef.current = tempUsers;
        setUsersToAdd(toAddRef.current);
        console.log(toAddRef.current);
    }

    function cancelAdd() {
        const buttons = document.getElementsByClassName('add-user-button')
        for (let b of buttons) {b.classList.remove('active')}
        toAddRef.current = [];
        setUsersToAdd(toAddRef.current);
        setShowUserChoiceModal(false);
    }

    async function addSelectedUsers() {
        setConfirmMessage('Are you sure you are ready to add these users to this Micro-Credential?')
        approveRef.current = async () => {
            const res = await UseCloudFunction('addUsersToMc', {'userList': usersToAdd, 'cst_key': userProfile.cst_key, 'mc_key': currentMc.mc_key, 'role': partOrEval});
            console.log(res);
            cancelAdd();
        }
    }

    return (
        <div className='view-mc-data'>
            <BackButton cancel={cancel} />
            <div className="g-space-1"></div>
            {
                (currentMc) &&
                <div className='g-card'>
                    <div className='mc-title-div'>
                        <div>
                            <img src={currentMc.imageUrl} className='mc-icon' />
                        </div>
                        <div className='mc-name'>
                            {currentMc.name}
                        </div>
                        <div className='meta'><button className='g-button text' onClick={() => navigate(`/admin/editmc/${currentMc.mc_key}`)}><FontAwesomeIcon icon={faPen} /></button></div>
                    </div>
                    <hr />
                    <div className='user-lists'>
                        <div className='g-list-item part-list'>
                            <div className='list-header'>
                                Participants ({partList ? Object.keys(partList).length : 0})
                                <button 
                                    className='g-button small-btn' 
                                    title='Add a participant' 
                                    onClick={() => launchAddUserModal('permiss_part')}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </button>
                            </div>
                            <hr />
                            <b>IN PROGRESS</b>
                            {
                                (partList) &&
                                Object.values(partList)
                                .sort((a,b) => a.lName > b.lName ? 1 : -1)
                                .map(u => (
                                    <div className='user-link' key={u.cst_key}>
                                        <div className='g-list-item'>
                                            <div>
                                                {u.fName} {u.lName}
                                            </div>
                                            <div className='buttons'>
                                                <button 
                                                    className='g-button small-btn'
                                                    onClick={() => navigate(`/admin/userdata/${u.cst_key}`)}
                                                >
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='g-space-2'></div>
                            <b>REGISTERED</b>
                            {
                                (nfeRegs && nfeRegs[mcId]) &&
                                Object.values(nfeRegs[mcId].regs)
                                .filter(u => (!partList || !partList[u.cst_key]) && (!evalList || !evalList[u.cst_key]))
                                .sort((a,b) => a.lName > b.lName ? 1 : -1)
                                .map(u => (
                                    <div className='user-link' key={u.cst_key}>
                                        <div className='g-list-item'>
                                            <div>
                                                {u.fName} {u.lName}
                                            </div>
                                            <div className='buttons'>
                                                {
                                                    (users[u.cst_key])
                                                    ?
                                                    <button 
                                                        className='g-button small-btn'
                                                        onClick={() => navigate(`/admin/userdata/${u.cst_key}`)}
                                                    >
                                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                    </button>
                                                    :
                                                    <div className='g-list-item'>
                                                        <span className='meta'>No active user</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='g-space-2'></div>
                            <b>COMPLETED</b>
                            {
                                (completeList) &&
                                Object.values(completeList)
                                .sort((a,b) => a.lName > b.lName ? 1 : -1)
                                .map(u => (
                                    <div className='user-link' key={u.cst_key}>
                                        <div className='g-list-item'>
                                            <div>
                                                {u.fName} {u.lName}
                                            </div>
                                            <div className='buttons'>
                                                <button 
                                                    className='g-button small-btn'
                                                    onClick={() => navigate(`/admin/userdata/${u.cst_key}`)}
                                                >
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='g-list-item eval-list'>
                            <div className='list-header'>
                                Evaluators ({evalList ? Object.keys(evalList).length : 0})
                                <button className='g-button small-btn' title='Add an evaluator' onClick={() => launchAddUserModal('permiss_eval')}><FontAwesomeIcon icon={faPlusCircle} /></button>
                            </div>
                            <hr />
                            {
                                (evalList) &&
                                Object.values(evalList).sort((a,b) => a.lName > b.lName ? 1 : -1)
                                .map(u => (
                                    <div className='user-link' key={u.cst_key}>
                                        <div className='g-list-item'>
                                            <div>
                                                {u.fName} {u.lName}
                                            </div>
                                            <div className='buttons'>
                                                <button 
                                                    className='g-button small-btn'
                                                    onClick={() => navigate(`/admin/userdata/${u.cst_key}`)}
                                                >
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </button>
                                                <button 
                                                    className='g-button small-btn'
                                                >
                                                    <FontAwesomeIcon icon={faBan} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
            <Modal show={showUserChoiceModal} cancel={() => setShowUserChoiceModal(false)} text={modalText} cls='narrow' >
                <div className='search-bar'>
                    <div className='field'>
                        <input text='text' onChange={(e) => searchPeople(e.target.value)} />
                    </div>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <div className='meta'>Chosen Users: {usersToAdd.length}</div>
                <div className="g-space-1"></div>
                <div className='user-choice-list'>
                {
                    (filteredUsers) &&
                    Object.values(filteredUsers).filter(u => u[partOrEval])
                    .sort((a, b) => (a.lName > b.lName) ? 1 : (a.lName === b.lName) ? ((a.fName > b.fName) ? 1 : -1) : -1 )
                    .map(u => (
                        <div key={u.cst_key} >
                            <button className='g-button text add-user-button' id={u.cst_key} onClick={() => toggleUser(u.cst_key)}>{u.fName} {u.lName}</button>
                        </div>
                    ))
                }
                </div>
                <div className="g-space-1"></div>
                <div className='buttons'>
                    {
                        (loading)
                        ?
                        <>
                            <button className='g-button' >Cancel</button>
                            <button className='g-button' ><ThreeDotsLoader /></button>
                        </>
                        :
                        <>
                            <button className='g-button' onClick={() => cancelAdd()}>Cancel</button>
                            {
                                (usersToAdd.length > 0)
                                ?
                                <button className='g-button primary' onClick={() => addSelectedUsers()}>Add Users</button>
                                :
                                <button className='g-button disabled'>Add Users</button>
                            }
                        </>
                    }
                </div>
            </Modal>
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(false)} message={confirmMessage} onApprove={approveRef.current} />
        </div>
    )
}

export default ViewMcData;