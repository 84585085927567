import React, { useEffect } from 'react'
import './full-page-loader.styles.scss';
import Loader from './loader.comp';


const FullPageLoader = ({ show, cancel }) => {
    const showHideClassName = show ? "full-page-loader display-block" : "full-page-loader display-none";

    useEffect(() => {
        // console.log(show)
		if (show) {
		    document.body.style.overflow = 'hidden'; 
		} else {
			document.body.style.overflow = 'unset';
		}
	},[show]) 

    return (
        <div className={showHideClassName}>
            {/* <div className='g-list-item'> */}
                <Loader />
            {/* </div> */}
        </div>
    )
}

export default FullPageLoader