import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/layout-auth/layout.comp'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContainer from '@/components/HOME/dashboard/dashboard-container.component'
import { getEarnedBadgesFromDb, getUniqueDocFromDb, getUserMcsFromDb } from '@/utils/getDataFromDb'
import { setEvaluatingList, setMyMcList, setMyProgress } from '@/state/slices/contentSlice'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import MyMicrocredentials from '@/components/HOME/my-microcredentials/my-microcredentials.component'
import EvaluatingMcList from '@/components/HOME/evaluating/evaluating-mc-list.component'
import MyBadgesContainer from '@/components/HOME/my-badges/my-badges-container.component'
import { UseCloudFunction } from '@/utils/firebase.utils'


const HomePage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ evaluatingList, setEvaluatingList ] = useState(null);
    const [ nfeRegs, setNfeRegs] = useState(null)
    const [ userProgressNumbers, setUserProgressNumbers ] = useState(null);
    const [ evalProgressNumbers, setEvalProgressNumbers ] = useState(null);
    const [ earnedBadges, setEarnedBadges ] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mcRef = useRef();
    const evalRef = useRef();
    const nfeRegsRef = useRef();
    const userProgressRef = useRef();
    const evalProgressRef = useRef();
    const badgesRef = useRef();
    let mcListener;
    let progNumListener;
    let badgesListener;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!userProfile) {
                navigate('/logout');
            }
        }, 3000)

        return () => {
            if (mcListener) mcListener();
            if (progNumListener) progNumListener();
            if (badgesListener) badgesListener();
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        if (!userProfile) {return;}
        getExistingMcs();
        getEarnedBadges();
        if (userProfile.permiss_eval) {
            navigate('evaluating');
        } else {
            navigate('mymicrocredentials');
        }
    }, [userProfile])

    function getExistingMcs() {
        getUserMcsFromDb({'cst_key': userProfile.cst_key, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            mcRef.current = {};
            // console.log(data);
            for (let mc of Object.values(data)) {
                const mcData = mc.mcData;
                // const progress = mc.progress;
                
                if (mcData.roles.includes('part')) {
                    mcRef.current = {...mcRef.current, ...{[mcData.mc_key]: mcData}};
                    dispatch(setMyMcList(mcRef.current));
                    getUserProgressNumber(mcData.mc_key, 'part');
                }
                if (mcData.roles.includes('eval')) {
                    evalRef.current = {...evalRef.current, ...{[mcData.mc_key]: mcData}};
                    setEvaluatingList(evalRef.current);
                    getUserProgressNumber(mcData.mc_key, 'eval');
                    getNfeRegistrants(mcData.mc_key);
                }
                
                // if (progress) {
                //     progressRef.current = {...progressRef.current, ...{[`${progress.mc_key}_${progress.cst_key}`]: progress}};
                //     setMyProgress(progressRef.current);
                // }
            }
        }
        function handleListener(unsub) {
            mcListener = unsub;
        }
    }

    function getUserProgressNumber(mc_key, partEval) {
        // console.log(mc_key)
        getUniqueDocFromDb({'path': `progressNumbers/${mc_key}`, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (!data) return;
            console.log(data);
            if (partEval === 'part') {
                userProgressRef.current = {...userProgressRef.current, ...{[mc_key]: data[userProfile.cst_key]}}
                setUserProgressNumbers(userProgressRef.current);
            } else {
                evalProgressRef.current = {...evalProgressRef.current, ...{[mc_key]: data}}
                setEvalProgressNumbers(evalProgressRef.current);
            }   
        }
        function handleListener(unsub) {
            progNumListener = unsub;
        }
    }

    async function getNfeRegistrants(mc_key) {
        const res = await UseCloudFunction(
            "getMCRegistrantsFromNFE",
            {
                "mc_key": mc_key,
                "cst_key": userProfile.cst_key,
            }
        )
        console.log(res);
        if (!res.data) return;
        let obj = {
            "count": res.data.length,
            "regs": {}
        }
        for (let u of res.data) {
            obj.regs[u.ind_cst_key] = {
                "fName": u.ind_first_name,
                "lName": u.ind_last_name,
                "cst_key": u.ind_cst_key,
            }
        } 
        nfeRegsRef.current = {...nfeRegsRef.current, ...{[mc_key]: obj}}
        setNfeRegs(nfeRegsRef.current);
    }

    function getEarnedBadges() {
        getEarnedBadgesFromDb({"cst_key": userProfile.cst_key, "callback": callback, "handleListener": handleListener})
        function callback(data) {
            badgesRef.current = {...badgesRef.current, ...{[data.mc_key]: data}}
            setEarnedBadges(badgesRef.current);
        }
        function handleListener(unsub) {
            badgesListener = unsub;
        } 
    }

    return (
        <div>
            <Layout>
                <Routes>
                    <Route path='' element={
                        <DashboardContainer 
                            evaluatingList={evaluatingList}
                            userProgressNumbers={userProgressNumbers}
                        />
                    }></Route>
                    <Route path='mymicrocredentials' element={
                        <MyMicrocredentials  
                            evaluatingList={evaluatingList}
                            userProgressNumbers={userProgressNumbers}
                        />
                    }></Route>
                    <Route path='evaluating/*' element={
                        <EvaluatingMcList
                            evaluatingList={evaluatingList}
                            evalProgressNumbers={evalProgressNumbers}
                        />
                    }></Route>
                    <Route path='mybadges' element={
                        <MyBadgesContainer 
                            earnedBadges={earnedBadges} 
                        />
                    }></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </Layout>
        </div>
    )
}

export default HomePage