import React from 'react';
import './mc-header.styles.scss';

const McHeader = ({ mcData }) => {


    
    return (
        <div className='mc-header'>
            <div className='icon'>
                <img src={mcData.imageUrl} />
            </div>
            <div className='text'>
                {mcData.name}
            </div>

        </div>
    )
}

export default McHeader