import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";

const config = {
    apiKey: "AIzaSyB_Kd4YZ3JPzHfCwANvwkk6hgrwWKTz3rM",
    authDomain: "ipa-microcredentials-v2.firebaseapp.com",
    databaseURL: "https://ipa-microcredentials-v2-default-rtdb.firebaseio.com",
    projectId: "ipa-microcredentials-v2",
    storageBucket: "ipa-microcredentials-v2.appspot.com",
    messagingSenderId: "485136849632",
    appId: "1:485136849632:web:fe6921ac678e36adfa8e85",
    measurementId: "G-1ED2W62XM7"
};

const app = initializeApp(config);

/**
 * 
 * AUTHENTICATION
 * 
 */


export const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
	console.log('signing in')
	signInWithPopup(auth, provider)
	.then((result) => {
		
	}).catch(err => console.log(err))
}

/**
 * 
 * EXPORT DATABASE REFS
 * 
 */

export const db = getFirestore();

export const rtDb = getDatabase();

/**
 * 
 * SIGN OUT
 * 
 */

export const signOut = async () => {
	await auth.signOut();
	return true;
}

/**
 *
 * Use Cloud Function
 *
 */

export const UseCloudFunction = async (command, data) => {
	console.log('cloud function', command)
    const functions = getFunctions();
	const processCommand = httpsCallable(functions, 'processCommand');
	const result = await processCommand({ 
		command: command,
		data: data
	})
  // console.log(result.data)
	const resData = result.data;
	return resData;
}