import React, { useEffect, useState } from 'react';
import './previous-attempts-modal.styles.scss';
import Modal from '@/components/SHARED/modal/modal.component';
import EvalReflectionBox from '../eval-reflection-box/eval-reflection-box.component';
import { format } from 'date-fns';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';

const PreviousAttemptsModal = ({ show, cancel, previousAttempts }) => {

    const [ display, setDisplay ] = useState(null);

    useEffect(() => {
        if (!show || !previousAttempts) return;
        console.log(previousAttempts);
        createDisplay();
    }, [show, previousAttempts])

    async function goToEvidenceFile(link) {
        const url = await getUploadUrlFromPath(link);
        window.open(url, '_new');
    }

    function createDisplay() {
        previousAttempts.sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)
        .map((a, index) => (
            <div key={a.timestamp + a.id + index}>
                <div className='date'>{format(new Date(a.timestamp), 'Pp')}</div>
                {
                    (a.exp) &&
                    a.exp.map((b, index) => (
                        (b) &&
                        <div key={b+new Date().getTime()}>
                            <EvalReflectionBox 
                                data={{'exp': b, 'upload': a.upload[index]}}
                                onClick={() => goToEvidenceFile(a.upload[index].link)} 
                            />
                        </div>
                    ))
                }
                {
                    (previousAttempts.length > 1 && index !== previousAttempts.length-1) &&
                    <hr />
                }
            </div>
        ))
    }

    return (
        <div className='previous-attempts-modal'>
            <Modal show={show} cancel={cancel} cls='wide' closeButton={true} text="View Previous Attempts" >
                <div className='attempts-list'>
                {
                    (previousAttempts.length > 0) &&
                    previousAttempts.sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)
                    .map((a, index) => (
                        <div key={a.timestamp + a.id + index}>
                            <div className='date'>{format(new Date(a.timestamp), 'Pp')}</div>
                            {
                                (a.exp) &&
                                a.exp.map((b, index) => (
                                    (b) &&
                                    <div key={b+new Date().getTime()}>
                                        <EvalReflectionBox 
                                            data={{'exp': b, 'upload': a.upload[index]}}
                                            onClick={() => goToEvidenceFile(a.upload[index].link)} 
                                        />
                                    </div>
                                ))
                            }
                            {
                                (previousAttempts.length > 1 && index !== previousAttempts.length-1) &&
                                <hr />
                            }
                        </div>
                    ))
                }
                </div>
            </Modal>
        </div>
    )
}

export default PreviousAttemptsModal