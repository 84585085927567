import React, { useEffect, useState } from 'react'
import './my-microcredentials.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { setMyProgress } from '@/state/slices/contentSlice';
import NewMcCheck from '../new-mc-check/new-mc-check.component';
import StatusBarWithIcons from './status-bar-with-icons.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const MyMicrocredentials = ({ evaluatingList, userProgressNumbers }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const myMcList = useSelector(state => state.contentData.myMcList);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function importOldProgress(e, mc_key) {
        setLoading(true);
        const mcId = e.substring(0, e.indexOf('-'));
        console.log(mcId);
        const res = await UseCloudFunction('postDataFromOldDatabase', {'url': 'https://us-central1-ipa-microcredentials.cloudfunctions.net/app/api/get-participant-data', 'body': {'mcId': mcId, 'email': userProfile.email}});
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong while getting your data from version 1. Please try again later.');
            setLoading(false);
            return;
        }
        const res2 = await UseCloudFunction('importOldProgress', {'cst_key': userProfile.cst_key, 'mc_key': mc_key, 'progress': res.oldData})
        console.log(res2);
        if (res2.error) {
            setAlertMessage('Something went wrong saving your old data to version 2. Please try again later.');
            setLoading(false);
            return;
        }
        let newMcData = {...myMcList[mc_key]};
        newMcData.imported = false;

    }

    return (
        <div className='my-microcredentials-container'>
            <div className='my-microcredentials-content'>
                <div className='g-card'>
                    <div className='section-title'>My Micro-Credentials</div>
                    <hr className='no-top-margin' />
                    <div className='mc-list'>
                    {
                        (myMcList && Object.keys(myMcList).length > 0) 
                        ?
                        Object.values(myMcList).map(m => {
                            const progNum = userProgressNumbers ? userProgressNumbers[m.mc_key] : null;

                            return (
                            <div key={m.mc_key}>
                                {
                                    (m.imported && (!progNum))
                                    ?
                                    <div  className='g-list-item mc-card no-shadow'>
                                        <div className='icon-container'>
                                            <img src={m.imageUrl} className='icon' />
                                        </div>
                                        <div className='text-container'>
                                            <div className='title'>{m.name}</div>
                                            <div className='buttons'>
                                                {
                                                    (loading) 
                                                    ?
                                                    <button className='g-button'><ThreeDotsLoader /></button>
                                                    :
                                                    <button className='g-button' onClick={() => importOldProgress(m.id, m.mc_key)}>Import Data from v1</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div  className='g-list-item mc-card'>
                                        <div className='icon-container'>
                                            <img src={m.imageUrl} className='icon' />
                                        </div>
                                        <div className='text-container'>
                                            <div className='title'>{m.name}</div>
                                            Status:
                                            <div className="g-space-0-5"></div>
                                            <div className='status-row'>
                                                <StatusBarWithIcons progressNumber={progNum} mcData={myMcList[m.mc_key]} />
                                                <button 
                                                    className='g-button'
                                                    onClick={() => navigate(`/microcredential/${m.mc_key}`)}    
                                                >
                                                    Open<FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )})
                            
                        :
                        <p>No Micro-Credentials Yet...</p>
                    }
                    </div>
                </div>
            </div>
            <NewMcCheck />
        </div>
    )
}

export default MyMicrocredentials;