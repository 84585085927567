import React from 'react'
import './admin-layout.styles.scss'
import TopBar from './top-bar/top-bar.component'
import SideBar from './side-menu/side-menu-container.component'



const AdminLayout = ({ children, admin }) => {
    
    return (
        <div className='admin-layout'>
            <TopBar />
            <SideBar admin={admin} />
            {/* <Header /> */}
                <div className='main-content'>
                    <div id='scroll-point'></div>
                    {
                        children
                    }
                </div>
            {/* <Footer /> */}
        </div>
    )
}

export default AdminLayout