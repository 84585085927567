import React, { useEffect, useRef, useState } from 'react';
import './evaluation-inputs.styles.scss';
import EvalReflectionBox from '../eval-reflection-box/eval-reflection-box.component';
import EvalToggles from '../eval-toggles/eval-toggles.component';
import EvalCommentBox from '../eval-comment-box/eval-comment-box.component';
import { format } from 'date-fns';
import EvalDetermination from '../eval-determination/eval-determination.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import PreviousAttemptsModal from './previous-attempts-modal.component';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';

/**
 * send currentData and id, 
 * sort data into content and eval
 * send eval and comments to cloud functions
 * 
 * @param {object} currentData 
 * @returns 
 */

const EvaluationInputs = ({ allProgress, currentData,  id, saveDirtyStatus }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ prog, setProg ] = useState(null);
    const [ detail, setDetail ] = useState(null);
    // const [ isBeingEvaluated, setIsBeingEvaluated] = useState(false);
    const [ newComment, setNewComment ] = useState('');
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ isDirty, setIsDirty ] = useState(false);
    
    
    const progRef = useRef();
    const dataRef = useRef();
    const commRef = useRef();
    const approveRef = useRef();

    useEffect(() => {
        setTimeout(() => {
        if (progRef.current === 'pending') {
                setProg(null);
            }
        }, 1000)
    }, [])

    useEffect(() => {
        if (!id) return;
        if (!allProgress || !allProgress[`content_${id}`]) {
            progRef.current = null;
            setProg(progRef.current);
            setDetail(null);
            // setDeclinedAttempts([]);
            return;
        }
        progRef.current = 'pending';
        // console.log(currentData);
        progRef.current = allProgress[`content_${id}`];
        setProg(progRef.current);
        let detailObj = {}
        for (let eo of Object.values(currentData.mcData.content)) {
            for (let det of Object.values(eo.comp)) {
                detailObj[det.id] = det
            }
        }
        let chosenDetail = detailObj[allProgress[`content_${id}`].detailId];
        setDetail(chosenDetail);
        if (allProgress[`eval_${id}`]) {
            dataRef.current = allProgress[`eval_${id}`];
            setFormData(dataRef.current);
            // console.log(dataRef.current);
            
        } const storedEval = JSON.parse(window.localStorage.getItem(`eval_${id}_${currentData.mcData.mc_key}`));
        if (storedEval) {
            dataRef.current = storedEval;
            setFormData(dataRef.current); 
        } 
        if (!allProgress[`eval_${id}`] && !storedEval) {
            dataRef.current = {};
            setFormData(dataRef.current);
        }
        
        
    }, [currentData, id])
    
    useEffect(() => {
        if (!formData || !allProgress) return;
        // console.log(formData);
        let mismatched = false;
        let keys = [
            'completed',
            'explained',
            'located',
            'comment'
        ]
        for (let key of keys) {
            console.log(formData[key]);
            console.log(allProgress[`eval_${id}`])
            if (!formData[key]) {
                continue;
            } else if (
                formData.comment && 
                (
                    !allProgress[`eval_${id}`] || 
                    formData[key] !== allProgress[`eval_${id}`][key]
                )
            ) {
                mismatched = true;
                break;
            } 
        }
        setIsDirty(mismatched);
        saveDirtyStatus(id, mismatched);
    }, [formData, allProgress])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        // console.log(dataRef.current);
        window.localStorage.setItem(`eval_${id}_${currentData.mcData.mc_key}`, JSON.stringify(dataRef.current))
    }
    
    function saveComment(text) {
        commRef.current = text;
        setNewComment(commRef.current);
    }

    async function saveEvaluation() {
        let tempData = {...dataRef.current};
        // console.log(tempData);
        const now = new Date().getTime();
        tempData.timestamp = now;
        tempData.id = id;
        
        if (id === 'precheck') {
            let newComments = {...tempData.prev_comments}
            if (newComments) {
                newComments[now + '_comment'] = {
                    'comment': tempData.comment,
                    'timestamp': now,
                    'eval_cst_key': userProfile.cst_key,
                }
            } else {
                newComments = {
                    [now + '_comment']: {
                        'comment': tempData.comment,
                        'timestamp': now,
                        'eval_cst_key': userProfile.cst_key,
                    }
                }
            }
            tempData.prev_comments = newComments;
            delete tempData.comment;       
            let approved = false;
            if (tempData.located && tempData.explained && tempData.completed) {
                approved = true;
            } 
            tempData.approved = approved;
            setConfirmMessage(`Your evaluation is that the submitted Precheck Artifact and Self-Analysis is ${approved ? 'APPROVED' : 'NOT APPROVED'}. If this is correct, click "OK" to continue.`);
            approveRef.current = () => {
                continueSavingEvaluation(tempData);
            }
        } else {
            continueSavingEvaluation(tempData);
        }
        
    }

    async function continueSavingEvaluation(tempData) {
        setLoading(true);
        // console.log('continued');
        // console.log(tempData);
        let emailData;
        if (tempData.id === 'precheck') {
            emailData = {
                subject: `Precheck Submission ${tempData.approved ? 'APPROVED!' : 'NOT APPROVED'} [MC: ${currentData.mcData.name}]`,
                head: `Precheck  Submission for Micro-Credential titled ${currentData.mcData.name} was ${tempData.approved ? 'APPROVED!' : 'NOT APPROVED'}`,
                // get eval emails on the back end
                body: `<p>${currentData.user.fName} ${currentData.user.lName},</p><p>Your <b>precheck content submission</b> has been evaluated for the Micro-Credential <b>${currentData.mcData.name}</b>.</p>
                <h3>Precheck Submission: ${tempData.approved ? 'APPROVED!' : 'NOT APPROVED'}</h3>
                <p>For more information on this decision and to continue your work on this Micro-Credential, please click the button below to go to the ELN MC Portal.</p>`
            }
        }
        const res = await UseCloudFunction(
            'saveEvaluation',
            {
                'evalData': tempData,
                'user': currentData.user,
                'cst_key': userProfile.cst_key,
                'mcData': currentData.mcData,
                'emailData': emailData,
            }
        )
        // console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
            return;
        }
        window.localStorage.removeItem(`eval_${tempData.id}_${currentData.mcData.mc_key}`);
        setIsDirty(false);
        saveDirtyStatus('precheck', false);
        setLoading(false);
    }

    return (
        <div className='evaluation-inputs'>
            {
                (prog) 
                ?
                <>
                
                
                
                {/* <div className="g-space-0-5"></div> */}
                {
                    (!formData.approved && allProgress.pending) &&
                    <>
                        <div className="g-space-0-5"></div>
                        <div className='card-head'>
                            Evaluation:
                        </div>
                        <EvalToggles data={formData} saveData={saveData} />
                        <EvalCommentBox data={formData} saveData={saveData} />
                    </>
                }
                {
                    (formData.prev_comments) &&
                    <div className='previous-comments'>
                        <p>Previous Comments:</p>
                        {/* <div className="g-space-0-5"></div> */}
                        {
                            Object.values(formData.prev_comments)
                            .sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
                            .map(c => (
                                <div key={c.timestamp} className='previous-comment'>
                                    <b>{format(new Date(c.timestamp), 'Pp')}: </b>
                                    {c.comment}
                                    <div className="g-space-0-5"></div>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    !prog.approved &&
                    <EvalDetermination data={formData} />
                }
                {/* <div className="g-space-1"></div> */}
                {
                    (isDirty) &&
                    <div className='unsaved-changes'>
                        <div className='g-list-item'>
                            Unsaved Changes <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                    </div>
                }
                
                {
                    (!formData.approved) &&      
                    <div className='buttons'>
                        {
                            (loading)
                            ?
                            <button className='g-button'><ThreeDotsLoader /></button>
                            :
                            (isDirty)
                            ?
                            <button className='g-button primary' onClick={() => saveEvaluation()}>Save Evaluation</button>
                            :
                            <button className='g-button disabled'>Save Evaluation</button>
                        }
                    </div>
                }
                </>
                :
                <h4>No submitted content yet...</h4>
            }
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default EvaluationInputs