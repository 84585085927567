import React, { useEffect, useState } from 'react'
import './upload-form.styles.scss';
import { useSelector } from 'react-redux';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';

const UploadForm = ({ mcData, mcProgress, cancel, setAlertMessage, chooseArtifact }) => {
    
    const profile = useSelector(state => state.userData.userProfile);
    const [ file, setFile ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const storage = getStorage();

    function handleFileUpload (e) {
        function cancelUpload() {
            document.getElementById('file-upload').value = null;
            setFile(null);
            // setFileName(null);
            return;
        }
        const file = e[0];
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        // console.log(ext);
        const allowed = ['jpg','bmp','png','gif','tif','xls','xlsx','csv','pdf','doc','docx', 'ppt', 'pptx']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            cancelUpload();
            return;
        }
        // console.log(file.size);
        if (file.size > 30 * 1024 * 1024) {
            setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 30MB.');
            cancelUpload();
            return;
        }
        setFile(file);
    };

    async function uploadArtifact() {
        const uploadName = document.getElementById('upload-label').value;
        if (!file) {
            setAlertMessage('Please upload a valid file before continuing.')
            return;
        }
        if (!uploadName) {
            setAlertMessage('Please give this upload a name before continuing.')
            return;
        }
        setLoading(true);
        const now = new Date().getTime();
        const pathname = `${mcData.mc_key}_${profile.cst_key}/${now}-${file.name}`
        const uploadRef = ref(storage, pathname)
        const metadata = {
            contentType: file.type,
            customMetadata: {
                cst_key: profile.cst_key,
                mc_key: mcData.mc_key,
            }
        };
        try {
            await uploadBytes(uploadRef, file, metadata)
        } catch (err) {
            setAlertMessage(`Something went wrong uploading your file. Please try again later. (${err})`);
            return;
        }
        const uploadObj = {
            'link': pathname,
            'name': uploadName,
            'timestamp': now,
            'type': file.type,
            'id': now + '-upload',
        }
        const res = await UseCloudFunction('saveUploadedArtifact', {'uploadObj': uploadObj, 'cst_key': profile.cst_key, 'mc_key': mcData.mc_key})
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong uploading your file. Please try again later. (${res.error})`);
            return;
        }
        chooseArtifact(uploadObj.id);
        clearForm();
    }

    function makeEvidenceSelection(id) {
        // console.log(id);
        chooseArtifact(id);
        clearForm();
    }

    function clearForm() {
        document.getElementById('file-upload').value = null;
        document.getElementById('upload-label').value = null;
        setLoading(false);
        cancel();
    }

    return (
        <div className='upload-form'>
        {
            (mcData && mcProgress) &&
            <>
            <h3>Choose an Uploaded Artifact</h3>
            <div className='g-space-20'></div>
            {
                (mcProgress && mcProgress.uploads)
                ?
                <div className='upload-list'>
                    {
                        Object.values(mcProgress.uploads).filter(u => !u.overview_closing)
                        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        .map(u => (
                            <EvidenceButton key={u.id} evidenceData={u} onClick={() => makeEvidenceSelection(u.id)} />
                        ))
                    }
                </div>
                :
                <p>No artifacts uploaded yet...</p>
            }
            <div className='g-space-40'></div>
            <h3>Or Upload a New Artifact</h3>
            <div className='g-space-0-5'></div>
            <p className='meta'>You can upload images, PDFs, slideshows, spreadsheets, and documents. If you are using Google files, please download them as MS Office files before uploading.</p>
            <p>If you would like to use a video or audio as evidence, please upload that file to a media service like YouTube, and make it viewable by anyone. Then, take the link to that video or audio and paste the link in a document, and upload that document as evidence.</p>
            <div className='g-space-20'></div>
            <input id="file-upload" type="file" onChange={(e) => handleFileUpload(e.target.files)} />
            <div className='g-space-20'></div>
            <div className='field'>
                <label>Give it a name to keep things organized</label>
                <input className='short' type='text' id='upload-label' />
            </div>
            <hr />
            <div className='buttons'>
                {
                    (loading)
                    ?
                    <>
                        <button className='g-button'><ThreeDotsLoader /></button>
                    </>
                    :
                    <>
                        <button className='g-button' onClick={() => clearForm()}>Cancel</button>
                        <button className='g-button primary' onClick={() => uploadArtifact()} >Upload and Choose Artifact</button>
                    </>
                }
            </div>
            </>
        }
        </div>
    )
}

export default UploadForm