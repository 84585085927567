import React, { useEffect, useState } from 'react'
import './portfolio-side-menu.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { checkPortfolioStatus } from './get-portfolio-status';
import { properCase } from '@/utils/properCase';

const PortfolioSideMenu = ({ mcData, mcProgress, activeId, dirtyForms }) => {

    const navigate = useNavigate();

    useEffect(() => {
        console.log(dirtyForms);
    }, [dirtyForms])

    return (
        <div className='portfolio-side-menu-container'>
            {
                (mcProgress) &&
                <>
                <div className={`menu-item ${activeId === 'summary' ? 'active' : ''}`} onClick={() => navigate(`/microcredential/${mcData.mc_key}/portfolio`)} >
                    Summary
                </div>
                <div className={`menu-item ${activeId === 'precheck' ? 'active' : ''}`} onClick={() => navigate(`/microcredential/${mcData.mc_key}/portfolio/precheck`)}>
                    Precheck
                    <div 
                        className='status' 
                        title={properCase(checkPortfolioStatus('precheck', {'progress': mcProgress, 'dirtyForms': dirtyForms}).class)}
                    >
                        <FontAwesomeIcon 
                            icon={checkPortfolioStatus('precheck', {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                            className={checkPortfolioStatus('precheck', {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                        />
                    </div>
                </div>
                <div className={`menu-item ${activeId === 'overview' ? 'active' : ''}`} onClick={() => navigate(`/microcredential/${mcData.mc_key}/portfolio/overview`)}>
                    Overview Narrative
                    <div 
                        className='status' 
                        title={checkPortfolioStatus('overview', {'progress': mcProgress, 'dirtyForms': dirtyForms}).title}
                    >
                        <FontAwesomeIcon 
                            icon={checkPortfolioStatus('overview', {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                            className={checkPortfolioStatus('overview', {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                        />
                    </div>
                </div>
                {
                    (mcData && mcData.content) &&
                    Object.values(mcData.content).sort((a,b) => a.num > b.num ? 1 : -1)
                    .map(e => (
                        <div key={`${e.id}-menu`} className='section' >
                            <div className='menu-header' id={e.id}>
                                    {e.name}
                            </div>
                            {
                                (e.comp)
                                &&
                                Object.values(e.comp).sort((a,b) => a.id > b.id ? 1 : -1)
                                .map(c => (
                                    <div 
                                        key={c.id} 
                                        className={`menu-item ${activeId === c.id ? 'active' : ''}`} 
                                        id={c.id+'-menu'} 
                                        onClick={() => navigate(`/microcredential/${mcData.mc_key}/portfolio/${c.id}`)}
                                    >
                                        {c.name}
                                        <div 
                                            className='status' 
                                            title={checkPortfolioStatus(c.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).title}
                                        >
                                            <FontAwesomeIcon 
                                                icon={checkPortfolioStatus(c.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                                                className={checkPortfolioStatus(c.id, {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
                <div className={`menu-item ${activeId === 'closing' ? 'active' : ''}`} id='closing-menu' onClick={() => navigate(`/microcredential/${mcData.mc_key}/portfolio/closing`)}>
                    Closing Reflection
                    <div 
                        className='status' 
                        title={checkPortfolioStatus('closing', {'progress': mcProgress, 'dirtyForms': dirtyForms}).title}
                    >
                        <FontAwesomeIcon 
                            icon={checkPortfolioStatus('closing', {'progress': mcProgress, 'dirtyForms': dirtyForms}).icon} 
                            className={checkPortfolioStatus('closing', {'progress': mcProgress, 'dirtyForms': dirtyForms}).class} 
                        />
                    </div>
                </div>
                </>
            }
        </div>
    )
}

export default PortfolioSideMenu