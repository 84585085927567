import React, { useEffect, useRef, useState } from 'react'
import './portfolio-pages.styles.scss';
import PortfolioUpload from './portfolio-upload.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import { useSelector } from 'react-redux';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faRotate, faSave } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

const OverviewClosingPortfolioPage = ({ mcData, mcProgress, path, saveDirtyStatus }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ file, setFile ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ isDirty, setIsDirty ] = useState(false);
    const [ uploadLoading, setUploadLoading ] = useState(false);
    const [ evalData, setEvalData ] = useState(null);
    const approveRef = useRef();
    const storage = getStorage();

    useEffect(() => {
        if (!file) return;
        window.localStorage.setItem(`${path}_${mcData.mc_key}`, JSON.stringify({
            'id': path,
            'name': file.name,
            'timestamp': new Date().getTime(),
            'type': file.type,
            'status': 'pending',
        }));
        setIsDirty(true);
    }, [file])

    useEffect(() => {
        if (!mcProgress || !path || !mcProgress[`eval_${path}`]) return;
        setEvalData(mcProgress[`eval_${path}`])
    }, [mcProgress, path])

    useEffect(() => {
        console.log(isDirty);
        saveDirtyStatus(path, 0, isDirty)
    }, [isDirty])

    async function goToUpload(link) {
        const url = await getUploadUrlFromPath(link);
        window.open(url, '_new');
    }

    async function removeArtifact() {
        setUploadLoading(true);
        await UseCloudFunction('removeArtifact', {
            'cst_key': profile.cst_key, 
            'mc_key': mcData.mc_key, 
            'path': `content_${path}`
        })
        setUploadLoading(false);
    }

    async function uploadArtifact() {
        if (!file) {
            setAlertMessage('Please upload a valid file before continuing.');
            return;
        }

        setConfirmMessage(`Are you sure you want to upload this file as your ${path === 'overview' ? 'Overview Narrative' : 'Closing Reflection'}?`);
        approveRef.current = () => {
            continueWithUpload();
        }

        async function continueWithUpload() {
            setLoading(true);
            const now = new Date().getTime();
            const pathname = `${mcData.mc_key}_${profile.cst_key}/${now}-${file.name}`;
            const uploadRef = ref(storage, pathname);
            const metadata = {
                contentType: file.type,
                customMetadata: {
                    cst_key: profile.cst_key,
                    mc_key: mcData.mc_key,
                }
            };
            try {
                await uploadBytes(uploadRef, file, metadata);
            } catch (err) {
                setAlertMessage(`Something went wrong uploading your file. Please try again later. (${err})`);
                return;
            }
            const uploadObj = {
                'link': pathname,
                'name': file.name,
                'timestamp': now,
                'type': file.type,
                'id': now + '-upload',
                'overview_closing': path,
            };
            const res = await UseCloudFunction('saveUploadedArtifact', {'uploadObj': uploadObj, 'cst_key': profile.cst_key, 'mc_key': mcData.mc_key});
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong uploading your file. Please try again later. (${res.error})`);
                return;
            }
            window.localStorage.setItem(`overview_${mcData.mc_key}`, JSON.stringify({
                'file': uploadObj.link,
                'id': uploadObj.overview_closing,
                'name': uploadObj.name,
                'timestamp': uploadObj.timestamp,
                'type': uploadObj.type,
            }));
            setLoading(false);
            setFile(null);
            setAlertMessage('Overview Reflection saved successfully!');
            setIsDirty(false);
        }
    }

    return (
        <div className='portfolio-page-container'>
            <div className='title-box'>    
                <h2>{path === 'overview' ? 'Overview Questions' : 'Closing Reflection'}</h2>
                {
                    (mcProgress && mcProgress[`content_${path}`] && mcProgress[`content_${path}`].status === 'approved')
                    ?
                    <div className='g-list-item approved'>Approved!</div>
                    :
                    (mcProgress && mcProgress[`content_${path}`] && mcProgress[`content_${path}`].status === 'declined') 
                    ?
                    <div className='g-list-item declined'>Declined</div>
                    : null
                }
            </div>
            
            <hr />
            <div className='portfolio-page-content'>
                <div dangerouslySetInnerHTML={{__html: mcData[path]}} />
            </div>
            <hr />
            {
                (evalData && evalData.prev_comments && !mcProgress.pending) &&
                <><div className='g-list-item prev-comment-item' >
                    <div className='title'>Evaluator's Comments:</div>
                    {
                        Object.values(evalData.prev_comments)
                        .sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
                        .map(e => (
                            <div key={e.timestamp}>
                                <div className="g-space-1"></div>
                                <div>{format(new Date(e.timestamp), 'P')}: <b>{e.comment}</b></div>
                            </div>
                        ))
                    }  
                </div>
                <div className="g-space-2"></div>
                </>
            }
            {
                (mcProgress.submissionsRemaining === 0 || mcProgress[`content_${path}`])
                ?
                <div className='upload-content'>
                    <h2>Uploaded {path === 'overview' ? 'Overview Narrative' : 'Closing Reflection'}</h2>
                    <div className="g-space-2"></div>
                    <div className='upload-with-edit'>
                        <div className='upload-data'>
                            <EvidenceButton evidenceData={mcProgress[`content_${path}`]} onClick={() => goToUpload(mcProgress[`content_${path}`].file)} />
                            {
                                (mcProgress[`content_${path}`].status === 'pending')
                                ?
                                <div className='complete-check saved'><FontAwesomeIcon icon={faCheck} size='lg' />Saved</div>
                                :
                                (mcProgress[`content_${path}`].status === 'approved')
                                ?
                                <div className='complete-check'><FontAwesomeIcon icon={faCheck} size='lg' />Approved</div>
                                :
                                null
                            }
                        </div>
                        {
                            (mcProgress.submissionsRemaining > 0 && !mcProgress.pending && mcProgress[`content_${path}`].status !== 'approved') &&
                            <div className='buttons'>
                                <button className='g-button med-btn' onClick={() => removeArtifact()}>
                                    {
                                        (uploadLoading)
                                        ?
                                        <FontAwesomeIcon icon={faRotate} className='spinning-icon' />
                                        :
                                        <FontAwesomeIcon icon={faPen} />
                                    }
                                </button>
                            </div>
                        }
                    </div>
                </div>
                :
                <div className='upload-input'>
                    <div className='upload-title-div'>
                        <h2>Upload Your {path === 'overview' ? 'Overview Narrative' : 'Closing Reflection'}</h2>
                        {
                            (isDirty) &&
                            <div className='complete-check need-to-save'><FontAwesomeIcon icon={faSave} size='lg' />Needs To Be Saved</div>
                        }
                    </div>
                    <p>You can upload .pdf, .doc, or .docx filetypes.</p>
                    <div className='upload-div'>
                        <PortfolioUpload setFile={setFile} />
                        {
                            (loading)
                            ?
                            <button className='g-button'><ThreeDotsLoader /></button>
                            :
                            (file)
                            ?
                            <button className='g-button primary' onClick={() => uploadArtifact()}>Save Upload to Portfolio</button>
                            :
                            <button className='g-button disabled'>Save Upload to Portfolio</button>
                        }
                    </div>
                </div>
            }
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default OverviewClosingPortfolioPage