import React from 'react'
import './side-menu-container.styles.scss'

import SideMenuUser from './side-menu.component'

const SideBar = ({ admin }) => {

    return (
        <div className="admin-sidebar">
            <div className="admin-sidebar-container">
                <div>
                   <SideMenuUser />
                </div>
            </div>
        </div>
    )
}

export default SideBar