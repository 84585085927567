import React from 'react'

import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function RequireAuth({ children }) {
	const currentUser = useSelector(state => state.userData.currentUser)
	// console.log(currentUser)
	const authed = useSignedIn(currentUser);
	// console.log(authed)
	// <Navigate to="/logout" replace />
	return authed === true ? children : <Navigate to="/logout" replace />
}

function useSignedIn(currentUser) {
 	return (currentUser && currentUser !== 'pending') ? true : false;
}