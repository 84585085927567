import React, { useState } from 'react'
import { ModalAlert } from '@/components/SHARED/modal/modal.component';

const PortfolioUpload = ({ setFile }) => {

    const [ alertMessage, setAlertMessage ] = useState(null);

    function handleFileUpload (e) {
        function cancelUpload() {
            document.getElementById('file-upload').value = null;
            setFile(null);
            // setFileName(null);
            return;
        }
        const file = e[0];
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['pdf','doc','docx']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            cancelUpload();
            return;
        }
        console.log(file.size);
        if (file.size > 2 * 1024 * 1024) {
            setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 2MB.');
            cancelUpload();
            return;
        }
        setFile(file);
    };

    
    
    return (
        <div>
            <input id="file-upload" type="file" onChange={(e) => handleFileUpload(e.target.files)} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(false)} message={alertMessage} />
        </div>
    )
}

export default PortfolioUpload