import { useMediaQuery } from "react-responsive";

export const mediaQueries = {
    mobile: {
        type: "maxWidth",
        size: 767,
    },
    tablet: {
        type: "maxWidth",
        size: 1023
    },
    largeTablet: {
        type: "maxWidth",
        size: 1200
    },
    desktop: {
        type: "minWidth",
        size: 1200
    }
}

export const screenSizes = [
    {
        'name': 'mobile',
        'minWidth': 0,
        'maxWidth': 767
    },
    {
        'name': 'tablet',
        'minWidth': 768,
        'maxWidth': 1023
    },
    {
        'name': 'largeTablet',
        'minWidth': 1024,
        'maxWidth': 1200
    },
    {
        'name': 'desktop',
        'minWidth': 1201,
        'maxWidth': 9600
    }
]

export const IsMobile = () => {
    const tf = useMediaQuery({maxWidth: 767});
    return tf ? true : false;
}

export const IsTablet = () => {
    const tf = useMediaQuery({maxWidth: 1023});
    return tf ? true : false;
}

export const IsLargeTablet = () => {
    const tf = useMediaQuery({maxWidth: 1200});
    return tf ? true : false;
}

export const IsDesktop = () => {
    const tf = useMediaQuery({minWidth: 1201});
    return tf ? true : false;
}


export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}

export const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1023 })
    return isTablet ? children : null
}

export const LargeTablet = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 1200 })
    return isTablet ? children : null
}

export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1201 })
    return isDesktop ? children : null
}
