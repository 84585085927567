export function toggleMenuItems(e) {
    setTimeout(() => {
        const items = document.getElementsByClassName('side-menu-item')
        for (let i of items) {
            i.classList.remove('active')
        }
        if (e) {
            // console.log(e)
            document.getElementById(e).classList.add('active')
        }
    }, 50);
}

export function setReqTab(e) {
    setTimeout(() => {
        // console.log('setting tab', e)
        const tabs = document.getElementsByClassName('drop-menu-item')
        for (let t of tabs) {
            t.classList.remove('active')
        }
        if (e) {
            document.getElementById(`${e}-tab`).classList.add('active')
        }
    }, 50);

}