import React, { useState, useEffect } from 'react'
import './sidebar-left.styles.scss'

import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faBug, faCertificate, faChartArea, faChartLine, faComment, faComments, faDashboard, faGear, faHome, faList, faListCheck, faPenSquare, faPenToSquare, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import BugModal from '../bug-modal/bug-modal.component';
import { mediaQueries } from '@/utils/responsiveQueries';
import { useSelector } from 'react-redux';

export function switchMenu(e) {
    const menuItems = document.getElementsByClassName('menu-item')
    for (let item of menuItems) {
        item.classList.remove('active')
    }
    if (document.getElementById(e)) {
        document.getElementById(e).classList.add('active');
    }
}

const SidebarLeft = ({ toggleMenu, orgId }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ viewDrop, setViewDrop ] = useState(false);
    const [ showServices, setShowServices ] = useState(false);
    const [ showSearchModal, setShowSearchModal ] = useState(false);
    const [ showBugModal, setShowBugModal ] = useState(false);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div>
            {
                (userProfile) &&
                <div className={`sidebar-container ${isMobileDevice ? 'mobile' : ''}`}>

                    
                    <div className="g-height-20"></div>
                    {/* <div className="button-container">
                        
                        <button className={`start-button ${showSearchModal ? 'clicked' : ''}`} onClick={() => setShowSearchModal(true)}>
                            <span className="circle" aria-hidden="true">
                                <span className="icon arrow"></span>
                            </span>
                            <span className="button-text">Get Started</span>
                        </button>
                    </div> */}
                    <div className={`drop-container ${viewDrop ? 'active' : ''}`}>
                        <div className="drop-menu">
                            
                            {/* <div className="drop-menu-item" id="newFolder" onClick={() => setShowSearchModal(true)}> 
                                <img src={require('assets/icons/play.png')} className="drop-menu-item-icon" />
                                Add To WatchList
                            </div>
                            <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newsuggestion')}> 
                                <img src={require('assets/icons/suggestion.png')} className="drop-menu-item-icon" />
                                Recommend
                            </div>
                            <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newchat')}> 
                                <img src={require('assets/icons/chat.png')} className="drop-menu-item-icon" />
                                New Chat
                            </div> */}
                            
                            
                        </div>
                    </div>
                    <div className="g-height-20"></div>
                    <div className="menu-container">
                        {
                            (userProfile.permiss_eval) &&
                            <>
                            <div className={`menu-item ${location.pathname.includes('evaluating') ? 'active' : ''}`} id='my-evaluating' onClick={() => navigate(`evaluating`)}>
                                <FontAwesomeIcon className='menu-item-icon' icon={faListCheck} size='lg' />
                                MC's I'm Evaluating
                            </div>
                            </>
                        }
                        {
                            (userProfile.permiss_part) &&
                            <>
                            {/* <div className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`} id='user-dashboard' onClick={() => navigate(`/home`)}>
                                <FontAwesomeIcon className='menu-item-icon' icon={faDashboard} size='lg' />
                                Dashboard
                            </div> */}
                            
                            <div className={`menu-item ${location.pathname.includes('mymicrocredentials') ? 'active' : ''}`} id="my-microcredentials" onClick={() => navigate('mymicrocredentials')}>
                                <FontAwesomeIcon className='menu-item-icon' icon={faCertificate} size='lg' />
                                My Micro-Credentials
                            </div>
                            <div className={`menu-item ${location.pathname.includes('mybadges') ? 'active' : ''}`} id="my-badges" onClick={() => navigate('mybadges')}>
                                <FontAwesomeIcon className='menu-item-icon' icon={faAward} size='lg' />
                                My Earned Badges
                            </div>
                            </>
                        }
                        <div className="menu-item" id='org-menu-bugs' onClick={() => setShowBugModal(true)}>
                            <FontAwesomeIcon className='menu-item-icon' icon={faBug} size='lg' />
                            Report a Bug
                        </div>
                    </div>
                    <BugModal show={showBugModal} cancel={() => setShowBugModal(false)} />
                </div>
            }
        </div>
    )
}

export default SidebarLeft;