import React from 'react'
import './evidence-button.styles.scss';
import { findIcon } from '@/utils/findIcon';

const EvidenceButton = ({ evidenceData, onClick }) => {

    return (
        <button key={evidenceData.link} className='g-button med-btn upload-btn' onClick={() => onClick()}>
            {findIcon(evidenceData.type)} {evidenceData.name.length > 40 ? evidenceData.name.substring(0, 40) + '...' : evidenceData.name}
        </button>
    )
}

export default EvidenceButton;