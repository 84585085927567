import React, { useState, useEffect } from 'react'
import './landing-page.styles.scss'

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'

// import { selectCurrentUser, selectDbData, selectProfile } from 'state/user/user.selectors'

const LandingPage = () => {

	const user = useSelector(state => state.userData.currentUser)
	const dbData = useSelector(state => state.userData.dbData)
	let { itemId }  = useParams()
	const [ userExists, setUserExists ] = useState(true)

	useEffect(() => {
		let href = window.location.origin
		let encoded = encodeURIComponent(href  + '/sso/?token={token}&intendedurl=%2Fhome%2F')
		console.log(encoded)
		window.open(`https://eweb.ilprincipals.org/eweb/?Site=eln&WebCode=LoginRequired&expires=yes&URL_success=${encoded}`, '_self')
	}, [])
	
	return (
		<div className='landing-page'>
			{/* <Routes>
				<Route path='' element={<LandingContent />}></Route>
				<Route path='/:itemId' element={<ItemPage />}></Route>
			</Routes> */}
		</div>
	)
}

export default LandingPage