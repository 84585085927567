import React from 'react';
import './upload-edit-modal.styles.scss';
import Modal from '../modal/modal.component';

const UploadEditModal = ({ show, cancel }) => {
    return (
        <div className='upload-edit-modal'>
            <Modal show={show} cancel={cancel} closeButton={true} >
                {
                    (show) &&
                    <div className='upload-edit-content'>
                        {show.name}
                    </div>
                }
            </Modal>
        </div>
    )
}

export default UploadEditModal