import React, { useEffect } from 'react'
import './dashboard-container.styles.scss'
import { switchMenu } from '@/components/layout-auth/sidebar-left/sidebar-left.component'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const DashboardContainer = ({ myMcList, evaluatingList, progressNumbers }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const navigate = useNavigate();

    return (
        <div className='dashboard-container'>
            <div className='dashboard-content'>
                <div className='g-card card1'>
                    Info goes here
                </div>
                <div className='g-card card2'>
                    Info goes here
                </div>
                <div className='g-card card3'>
                    Info goes here
                </div>
                <div className='g-card card4'>
                    Info goes here
                </div>
                <div className='g-card card5'>
                    Info goes here
                </div>
            </div>
        </div>
    )
}

export default DashboardContainer