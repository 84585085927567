import React, { useEffect, useRef, useState } from 'react'
import './component-modal.styles.scss';

import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component'

const ComponentModal = ({ show, cancel, formData, saveData, edit, clearEdit }) => {

    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ tempComp, setTempComp ] = useState({})
    const [ loading, setLoading ] = useState(false);
    const compRef = useRef();

    useEffect(() => {
        if (!edit) {return;}
        console.log(edit);
        compRef.current = formData.content[edit.eo].comp[edit.comp];
        setTempComp(compRef.current);
    }, [edit])

    function saveCompData(key, value) {
        if (key === 'id') {
            value = value.toUpperCase().replace(/[^A-Za-z0-9]/g,"");
        }
        compRef.current = {...compRef.current, ...{[key]: value}}
        setTempComp(compRef.current);
    }

    function handleSubmit() {
        console.log(tempComp);
        if (!tempComp.eo || !tempComp.id || !tempComp.desc) {
            setAlertMessage('Please complete the form before moving on.');
            return;
        }
        let newContent = {...formData.content};
        if (newContent[tempComp.eo].comp[tempComp.id]) {
            setConfirmMessage(`This will overwrite the description for the Component titled ${tempComp.id} in ${formData.content[tempComp.eo].name}. Do you want to continue?`);
            return;
        } else {
            approveSubmit();
        }
    }

    function approveSubmit() {
        const newComp = {...formData.content[tempComp.eo].comp}
        console.log(newComp);
        newComp[tempComp.id] = {
            'desc': tempComp.desc,
            'id': tempComp.id,
            'name': tempComp.id,
            'eo': tempComp.eo
        }
        const newEo = {...formData.content[tempComp.eo], ...{'comp': newComp}};
        // console.log(newComps);
        const newContent = {...formData.content, ...{[tempComp.eo]: newEo}}
        saveData('content', newContent);
        clearForm();
    }

    function clearForm() {
        compRef.current = {}
        setTempComp(compRef.current);
        cancel();
    }

    return (
        <div className='component-modal'>
            <Modal show={show} cancel={cancel} text='Add a component' closeButton={false}>
                <div className='field inline'>
                    <label>Choose an Essential Outcome</label>
                    <select  className={edit && 'disabled'} value={tempComp && tempComp.eo ? tempComp.eo : ''} onChange={(e) => saveCompData('eo', e.target.value)}>
                        <option value='' disabled>Choose...</option>
                        {
                            (formData.content) &&
                            Object.values(formData.content)
                            .toSorted((a,b) => a.num > b.num ? 1 : -1)
                            .map(e => (
                                <option key={e.id} value={e.id}>{e.name}</option>
                            ))     
                        }
                    </select>
                </div>
                <div className='field inline'>
                    <label>Component Label (1A, 3B, etc.)</label>
                    <input type='text' value={tempComp && tempComp.id ? tempComp.id : ''} onChange={e => saveCompData('id', e.target.value)}/>
                </div>
                <div className='field'>
                    <label>Content</label>
                    <textarea rows="3" value={tempComp && tempComp.desc ? tempComp.desc : ''} onChange={e => saveCompData('desc', e.target.value)}></textarea>
                </div>
                <div className='buttons'>
                    <button className='g-button' onClick={() => clearForm()}>Cancel</button>
                    {
                        (loading) 
                        ?
                        <button className='g-button submit'><ThreeDotsLoader /></button>
                        :
                        <button className='g-button submit primary' onClick={() => handleSubmit()}>Save Component</button>
                    }
                </div>
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} onApprove={approveSubmit} message={confirmMessage} />
        </div>
    )
}

export default ComponentModal