import { faCircleCheck, faCircle as faCircleClosed, faExclamation, faExclamationCircle, faPen, faSave, faUpload, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCircleCheck as circleCheckEmpty, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { compareArrays } from '@/utils/compareArrays';

export function checkPortfolioStatus(path, currentData) {
    let submitted = false;
    if (path === 'precheck' && currentData.progress.content_precheck && currentData.progress.content_precheck.status === 'pending') {
        submitted = true;
    } else if (currentData.progress.submitted) {
        const subArr = Object.values(currentData.progress.submitted)
        .sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)
        const lastSub = subArr[0];
        if (!lastSub.retStatus) {
            submitted = true;
        }
    }
    const prog = currentData.progress;
    const dirtyForms = currentData.dirtyForms;
    
    if (path === 'materials') {
        if (currentData.progress.content_materials && currentData.progress.content_materials.timestamp) {
            return {'icon': faCircleCheck, 'class': 'done', 'title': 'Completed'}
        } else {
            return {'icon': faCircle, 'class': 'undone', 'title': 'Incomplete'}
        }
    }

    let res = {'icon': faCircle, 'class': 'undone'};
    if (prog[`content_${path}`]) {
        
        if (prog[`content_${path}`].status === 'pending') {
            if (submitted) {
                res = {'icon': faCircleCheck, 'class': 'saved', 'title': 'Submitted'}
            } else {
                res = {'icon': circleCheckEmpty, 'class': 'saved', 'title': 'In Progress'}
            }
        } 
        if (prog[`content_${path}`].status === 'saved') {
            res = {'icon': circleCheckEmpty, 'class': 'saved', 'title': 'Saved'}
        }
        // if (storedProg[path] && !prog[`content_${path}`]) {
        //     res = {'icon': faSave, 'class': 'need-to-save', 'title': 'No saved content'};
        // } 
        // if (storedProg[path] && (!compareArrays(storedProg[path].exp, prog[`content_${path}`].exp) || !compareArrays(storedProg[path].upload, prog[`content_${path}`].upload))) {
            // console.log(storedProg[path].exp, prog[`content_${path}`].exp)
            // console.log(compareArrays(storedProg[path].exp, prog[`content_${path}`].exp))
        if (prog[`content_${path}`].status === 'approved') {
            res = {'icon': faCircleCheck, 'class': 'done', 'title': 'Approved'}
        } 
        if (prog[`content_${path}`].status === 'declined') {
            res = {'icon': faCircleXmark, 'class': 'declined', 'title': 'Declined'}
        } 
    } 
    if (dirtyForms[path]) {
        if (Object.values(dirtyForms[path]).includes('upload')) {
            res = {'icon': faExclamationCircle, 'class': 'need-to-save', 'title': 'Missing Upload'};
        }
        if (Object.values(dirtyForms[path]).includes('explain')) {
            res = {'icon': faExclamationCircle, 'class': 'need-to-save', 'title': 'Missing Explanation'};
        }
        if (Object.values(dirtyForms[path]).includes(true)) {
            res = {'icon': faSave, 'class': 'need-to-save', 'title': 'Doesn\'t match'};
        } 
    }

    return res;
}