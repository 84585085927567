import React, { useEffect, useRef, useState } from 'react';
import './mc-portfolio-page.styles.scss';
import TitleCard from '../title-card/title-card.component';
import PortfolioSideMenu from './portfolio-side-menu.component';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import ContentPortfolioPage from './portfolio-pages/content-portfolio-page.component';
import SummaryPortfolioPage from './portfolio-pages/summary-portfolio-page.component';
import { scrollIntoViewIfHidden } from '@/utils/scrollIntoViewIfHidden';
import PortfolioStatusBar from './portfolio-status.component';
import { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import { compareArrays } from '@/utils/compareArrays';
import PrecheckPortfolioPage from './portfolio-pages/precheck-portfolio-page.component';
import UploadListModal from './upload-list-modal.component';
import { findCurrentProgress } from '@/utils/findCurrentProgress';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { format } from 'date-fns';
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component';
import OverviewClosingPortfolioPage from './portfolio-pages/overview-closing.component';
import UploadEditModal from '@/components/SHARED/upload-edit-modal/upload-edit-modal.component';

const McPortfolioPage = ({ mcData, mcProgress }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ activeId, setActiveId ] = useState(null);
    const [ display, setDisplay ] = useState(null);
    const [ content, setContent ] = useState(null);
    const [ progressValue, setProgressValue ] = useState(0);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ hideDeny, setHideDeny ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(false);
    const [ dirtyForms, setDirtyForms ] = useState({});
    const [ storedProgress, setStoredProgress ] = useState({});
    const [ approveButtonText, setApproveButtonText ] = useState(null);
    const [ denyButtonText, setDenyButtonText ] = useState(null);
    const { sectionId } = useParams();
    const [ viewUploads, setViewUploads ] = useState(false);
    const [ uploadToEdit, setUploadToEdit ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const approveRef = useRef();
    const denyRef = useRef();
    const dirtyRef = useRef({});
    let timeout;
    

    // Sets up useBlocker which will keep user from moving away from dirty forms

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
        location.pathname !== `/microcredential/${mcData.mc_key}/portfolio` &&
        (dirtyForms[sectionId] && Object.values(dirtyForms[sectionId]).filter(d => d !== false).length > 0) &&
        currentLocation.pathname !== nextLocation.pathname
    ); 

    useEffect(() => {
        return () => {
            blocker = null;
            if (timeout) clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        if (!blocker || blocker.state !== 'blocked') return;
        setApproveButtonText('Leave Page');
        setDenyButtonText('Cancel');
        setConfirmMessage('You have content for this detail that you need to save.')
        approveRef.current = () => {
            blocker.proceed();
            setConfirmMessage(null);
        }
        denyRef.current = () => {
            blocker.reset();
            setConfirmMessage(null);
        }
    }, [blocker])


    // checks mcData and created content to make sure the current sectionId is actually
    // part of the MC. If not, it navigates to '/home'

    useEffect(() => {
        if (!mcData || !content) return;
        getId();
        let sections = ['precheck', 'overview', 'closing'];
        for (let c of Object.values(mcData.content)) {
            for (let d of Object.values(c.comp)) {
                sections.push(d.id.toUpperCase());
            }
        }
        if (sectionId && !sections.includes(sectionId)) {
            navigate('/home');
            return;
        }
        scrollIntoViewIfHidden(document.getElementById('topofpage'));
    }, [sectionId, mcData, content])


    // Denies user from accessing Portfolio if no approved Precheck exists
    // navigates back to MC home
    // also sets up Content and gets stored data

    useEffect(() => {

        console.log('checking for access, then getting stored data')
        if (!mcData || !mcProgress) return;
        if (mcProgress.content_precheck && mcProgress.content_precheck.status !== 'approved') {
            setHideDeny(true);
            setConfirmMessage('You must have an approved Precheck Submission before accessing your portfolio.');
            approveRef.current = () => {
                setHideDeny(false);
                navigate(`/microcredential/${mcData.mc_key}`);
                return;
            } 
        }
        setUpContent();
        // getStoredData();
    }, [mcData, mcProgress])

    useEffect(() => {
        if (!storedProgress) return;
        // testProgress();
    }, [storedProgress]);

    function getStoredData() {
        let storedData = {};
        let content = [];
        // console.log(mcData);
        for (let eo of Object.values(mcData.content)) {
            for (let c of Object.values(eo.comp)) {
                let newC = {...c};
                newC.eoName = eo.name;
                newC.eoDesc = eo.desc;
                content.push(newC);
            }
        }
        for (let k of content) {
            // console.log(k);
            const stored = JSON.parse(window.localStorage.getItem(`${k.id}_${mcData.mc_key}`))
            if (stored) {
                storedData[k.id] = stored;
            }
        }
        // console.log(storedData);
        setStoredProgress(storedData);
    }

    function testProgress() {
        // console.log('testing progress');
        for (let sectionId of Object.keys(storedProgress)) {
            // console.log(section);
            const prog = {...mcProgress[`content_${sectionId}`]};
            const stored = {...storedProgress[sectionId]};
            // console.log('prog', prog);
            // console.log('stored', stored);
            if (!stored || sectionId === 'overview' || sectionId === 'closing' || sectionId === 'materials') {
                
                continue;
            }
            // console.log(sectionId);
            if (!prog || !compareArrays(prog.exp, stored.exp) || !compareArrays(prog.upload, stored.upload)) {
                saveDirtyStatus(sectionId, 0, true);
            } else {
                saveDirtyStatus(sectionId, 0, false);
            }
        }
        // if (!mcProgress[`content_${sectionId}`] || (formData.exp[evNumber] !== mcProgress[`content_${sectionId}`].exp[evNumber] || formData.upload[evNumber] !== mcProgress[`content_${sectionId}`].upload[evNumber])) {
        //     setIsDirty(true);
        // } else {
        //     setIsDirty(false);
        // }
    }

    function saveDirtyStatus(path, num, bool) {
        console.log('changing dirty status')
        const newDirtyObj = {...dirtyRef.current};
        const newDirtyPath = {...newDirtyObj[path]};

        // Only update if the value is different to avoid unnecessary re-renders
        if (newDirtyPath[num] !== bool) {
            newDirtyPath[num] = bool;
            newDirtyObj[path] = newDirtyPath;
            dirtyRef.current = newDirtyObj;
            setDirtyForms(dirtyRef.current);
        }
    }

    function getId() {
        if (!location) return;
        const p = location.pathname;
        // console.log(p)
        let id = p.substring(p.indexOf('/portfolio') + 10, p.length);
        let tempId;
        if (id.includes('/')) {
            tempId = id.slice(1);
            id = tempId;
        } else {
            id = 'summary';
        }
        
        setActiveId(id);
    }

    function setUpContent() {
        let tempContent = [
            {
                'num': -2,
                'id': 'materials',
                'name': 'Viewed Required Micro-Credential Materials'
            },
            {
                'num': -1,
                'id': 'precheck',
                'name': 'Precheck Artifact and Self-Analysis'
            },
            {
                'num': 0,
                'id': 'overview',
                'name': 'Overview Narrative'
            }
        ]
        let contentCount = 4;
        for (let eo of Object.values(mcData.content).sort((a,b) => a.num > b.num ? 1 : -1)) {
            // for (let co of Object.values(eo.comp)) {
            //     let newCo = {...co};
            //     count++
            //     newCo.num = count;
            //     tempContent.push(newCo);
                
            // }
            tempContent.push(eo);
            if (eo.comp) {
                contentCount += Object.values(eo.comp).length;
            }
        }
        tempContent.push({
            'num': Object.values(mcData.content).length + 1,
            'id': 'closing',
            'name': 'Closing Reflection'
        })
        setContent(tempContent);
        let completePortfolioCount = 0;
        for (let p of Object.keys(mcProgress)) {
            if (p.includes('content_') && mcProgress[p].status !== 'declined') {
                completePortfolioCount++;
            }
        }
        // console.log(completePortfolioCount);
        const progPercent = ((completePortfolioCount/contentCount) * 100).toFixed(0).toLocaleString();
        setProgressValue(progPercent);
        // console.log(progPercent);
        document.querySelector(".progress-bar").style.width = progPercent + "%";
        // const progNum = findCurrentProgress(mcProgress, mcData);
        // console.log(progNum);
    }

    async function submitPortfolio() {
        setApproveButtonText('Submit Portfolio');
        setDenyButtonText('Cancel');
        let notChanged = []
        Object.values(mcProgress).map(p => {
            if (p.status === 'declined') {
                notChanged.push(p.id);
            }
        })
        console.log(notChanged);
        if (notChanged.length > 0) {
            const changedList = notChanged.map(m => `<li key=${m}>${m.toUpperCase()}</li>`).join('')
            console.log(changedList);
            setConfirmMessage(
            `<div>
                <p><b>WARNING:</b> At least one part of your portfolio has not been changed since the last time it was submitted:</p>
                <ul>
                    ${changedList}
                </ul>
                <p>${`Are you sure you want to use ${mcProgress.submissionsRemaining === 1 ? '<b>your final attempt</b>' : 'one of your remaining attempts'} to submit the same content again?`}</p>
            </div>`)
            approveRef.current = () => {
                timeout = setTimeout(() => {
                    validateSubmission();
                }, 500);
            }
            return;
        } else {
            validateSubmission();
        }

        function validateSubmission() { 
            setConfirmMessage(`Are you sure you want to use ${mcProgress.submissionsRemaining === 1 ? 'your final attempt' : 'one of your remaining attempts'} to submit your portfolio? This action cannot be undone.`);
            approveRef.current = () => {
                continueSubmission();
            }
        }

        async function continueSubmission() {
            setLoading(true);
            const now = new Date().getTime();
            const evalEmailData = {
                subject: `FINAL Micro-Credential Portfolio Submitted - [${mcData.name}]`,
                head: `${userProfile.name} submitted a complete portfolio for the "${mcData.name}" Micro-Credential`,
                // get eval emails on the back end
                body: `<p>${userProfile.name} submitted their complete portfolio with evidence and analysis to be evaluated. This is submission No. ${mcProgress.submitted ? Object.values(mcProgress.submitted).length + 1 : '1'}.<br><br>Click on the button below to go to the ELN Micro-Credential Portal and evaluate his/her submission.</p>`
            }
            const partEmailData = {
                email: userProfile.email,
                subject: `Your Finalized MC Portfolio has been submitted [${mcData.name}]`,
                head: 'Your Finalized Micro-Credential Portfolio Has Been Submitted',
                // get eval emails on the back end
                body: `<p>${userProfile.name},</p><p>Your finalized portfolio was submitted on ${format(new Date(), 'P')} in the Micro-Credential <b>${mcData.name}</b>. Please allow <b>10 business days</b> for your portfolio to be assessed. Once the evaluator has recorded a decision and provided notes on your portfolio, you will receive another email like this one. If it is approved, you will then have the opportunity to complete an evaluation and receive your badge proving you've completed the Micro-Credential requirements. If it is not approved, ${mcProgress.submissionsRemaining > 1 ? `you will have ${mcProgress.submissionsRemaining - 1} more attempt(s) to have your portfolio approved.` : 'you will have to purchase the Micro-Credential again in order to submit the portfolio for approval.'}</p><p>If you do not receive notification that your portfolio has been assessed within 10 business days, please contact <a href="mailto:support@edleadersnetwork.org" target="_blank">support@edleadersnetwork.org</a>.</p>`
            }
            const obj = {
                'ts': now,
                'mc_key': mcData.mc_key,
                'cst_key': userProfile.cst_key,
                'userProfile': userProfile,
                'evalEmailData': evalEmailData,
                'partEmailData': partEmailData,
            }
            const res = await UseCloudFunction('submitPortfolio', obj)
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
                return;
            }
            for (let k of Object.keys(mcProgress)) {
                if (k.includes('content_')) {
                    const id = k.split('_')[1];
                    window.localStorage.removeItem(`${id}_${mcData.mc_key}`);
                }
            }
            setLoading(false);
            setAlertMessage('Your portfolio has been submitted correctly. It may take up to 10 business days for you to receive a response on your submission.');
        }
    }

    return (
        <div className='mc-portfolio-page'>
            <TitleCard mcData={mcData} title='Build Your Portfolio' />
            <a id="topofpage"></a>
            <div className='portfolio-content'>
                <div>
                    <div className='g-card'>
                        <h4>Menu</h4>
                        <hr className='no-margin' />
                        <PortfolioSideMenu 
                            mcData={mcData} 
                            mcProgress={{...mcProgress}} 
                            activeId={activeId} 
                            dirtyForms={dirtyForms} 
                        />
                    </div>
                </div>
                <div className='content-div'>
                    <div className='g-card'>
                        <PortfolioStatusBar 
                            mcData={mcData} 
                            mcProgress={mcProgress} 
                            progressValue={progressValue} 
                            setViewUploads={setViewUploads} 
                            submitPortfolio={submitPortfolio} 
                        />
                    </div>
                    <div className='g-card'>
                        {
                            (activeId) &&
                            (activeId === 'summary') 
                            ?
                            <SummaryPortfolioPage 
                                mcData={mcData} 
                                mcProgress={{...mcProgress}} 
                                content={content} 
                                dirtyForms={dirtyForms} />
                            :
                            (activeId === 'overview')
                            ?
                            <OverviewClosingPortfolioPage 
                                mcData={mcData} 
                                mcProgress={{...mcProgress}} 
                                path='overview' 
                                saveDirtyStatus={saveDirtyStatus} 
                            />
                            :
                            (activeId === 'closing')
                            ?
                            <OverviewClosingPortfolioPage 
                                mcData={mcData} 
                                mcProgress={{...mcProgress}} 
                                path='closing' 
                                saveDirtyStatus={saveDirtyStatus} 
                            />
                            :
                            (activeId === 'precheck')
                            ?
                            <PrecheckPortfolioPage 
                                mcData={mcData} 
                                mcProgress={{...mcProgress}} 
                            />
                            :
                            <ContentPortfolioPage 
                                mcData={mcData} 
                                mcProgress={{...mcProgress}} 
                                dirtyForms={dirtyForms} 
                                saveDirtyStatus={saveDirtyStatus} 
                            />
                        }
                    </div>
                </div>
            </div>
            <ModalConfirm 
                show={confirmMessage} 
                cancel={() => setConfirmMessage(null)} 
                message={confirmMessage} 
                onApprove={approveRef.current} 
                onDeny={denyRef.current}
                approveButton={approveButtonText}
                denyButton={denyButtonText} 
                hideDeny={hideDeny}
            />      
            <UploadListModal 
                show={viewUploads} 
                cancel={() => setViewUploads(false)} 
                mcProgress={{...mcProgress}} 
                setUploadToEdit={setUploadToEdit}
            />
            <UploadEditModal
                show={uploadToEdit}
                cancel={() => setUploadToEdit(null)}

            />
            <ModalAlert 
                show={alertMessage}
                cancel={() => setAlertMessage(false)}
                message={alertMessage}
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default McPortfolioPage