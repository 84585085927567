import React, { useEffect } from 'react';
import './my-badges-container.styles.scss';
import { format } from 'date-fns';

const MyBadgesContainer = ({ earnedBadges }) => {

    

    return (
        <div className='my-badges-container'>
            <div className='g-card badges-card'>
                <div className='section-title'>My Badges</div>
                <hr className='no-top-margin' />
            
                <div className='badge-list'>
                    {
                        (earnedBadges) &&
                        Object.values(earnedBadges)
                        .sort((a,b) => a.ts > b.ts ? 1 : -1)
                        .map(b => (
                            <button key={b.mc_key} className='g-button'>
                                <img src={b.mc_imageUrl} className='badge-image' />
                                <div className='title'>
                                    {b.mc_name}
                                </div>
                                <div className='meta'>Earned: {format(new Date(b.ts), "P")}</div>
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default MyBadgesContainer