import React from 'react'
import './upload-list-modal.styles.scss';
import Modal from '@/components/SHARED/modal/modal.component';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const UploadListModal = ({ show, cancel, mcProgress, setUploadToEdit }) => {

    async function openUpload(link) {
        const url = await getUploadUrlFromPath(link);
        window.open(url, '_new');
    }

    function editUpload(u) {
        setUploadToEdit(u);
        cancel();
    }

    return (
        <Modal show={show} cancel={cancel} closeButton={true} text='Current Upload List'>
            <div className='upload-list-modal'>
                {
                    (mcProgress && mcProgress.uploads) &&
                    Object.values(mcProgress.uploads).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    .map(u => (
                        <div key={u.id} className='upload-row'>
                            <EvidenceButton 
                                evidenceData={u} 
                                onClick={() => openUpload(u.link)} 
                            />
                            {/* <div className='buttons'>
                                <button 
                                    className='g-button small-btn'
                                    title='Edit Upload'
                                    onClick={() => editUpload(u)}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                            </div> */}
                        </div>
                    ))
                }
                {/* <div className="g-space-1"></div> */}
            </div>
        </Modal>
    )
}

export default UploadListModal