export function showBox(id) {

	const els = document.getElementsByClassName('content-box')
    for (let e of els) {
        // console.log(e.style.height)
        if (e.style.height > '0px' || id === e.id) {
            // console.log(e.id)
            toggleBoxDisplay(e.id)
        }
    }
}

export function toggleBoxDisplay(id) {
	
	// console.log(id)
	const elem = document.getElementById(id)
	elem.style.height = '';
	elem.style.transition = 'none';

	const startHeight = window.getComputedStyle(elem).height;

	// Remove the collapse class, and force a layout calculation to get the final height
	elem.classList.toggle('hidden');
	const height = window.getComputedStyle(elem).height;

	// Set the start height to begin the transition
	elem.style.height = startHeight;

	// wait until the next frame so that everything has time to update before starting the transition
	requestAnimationFrame(() => {
		elem.style.transition = '';
	
		requestAnimationFrame(() => {
			elem.style.height = height
		})
	})

	

	// Clear the saved height values after the transition
	// elem.addEventListener('transitionend', () => {
	//     elem.style.height = '';
	//     elem.removeEventListener('transitionend', arguments.callee);
	// }); 
}