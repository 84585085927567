import React, { useEffect, useState } from 'react'
import './admin-users.styles.scss'
import { toggleMenuItems } from '../admin-layout/side-menu/toggle-items'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import ManageUsers from './manage-users.component'
import { useDispatch, useSelector } from 'react-redux'
import ImportUsers from './import-users.component'

export function switchUserButtons(e) {
    const buttons = document.getElementsByClassName('admin-user-button');
    for (let b of buttons) {
        b.classList.remove('primary')
    }
    document.getElementById(e).classList.add('primary');
}

const AdminUsers = ({ users, importedUsers, oldUsers }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className='admin-users'>
            <div className='user-container'>
                <div className='users-button-row'>
                    <button className='g-button admin-user-button' id='users' onClick={() => navigate('/admin/users')}>All Users</button>
                    <button className='g-button admin-user-button' id='participants' onClick={() => navigate('participants')}>Participants</button>
                    <button className='g-button admin-user-button' id='evaluators' onClick={() => navigate('evaluators')}>Evaluators</button>
                    <button className='g-button admin-user-button' id='administrators' onClick={() => navigate('administrators')}>Administrators</button>
                    <button className='g-button admin-user-button' id='import' onClick={() => navigate('import')}>Import Users</button>
                </div>
                <div className="g-height-20"></div>
                <hr />
                <div className="g-height-20"></div>
                
                <Routes>
                    <Route path='' element={
                        <ManageUsers
                            users={users} 
                            sortId="all" 
                        />
                    }></Route>
                    <Route path='participants' element={
                        <ManageUsers 
                            users={users}
                            sortId='part' 
                        />
                    }></Route>
                    <Route path='evaluators' element={
                        <ManageUsers 
                            users={users}
                            sortId='eval' 
                        />
                    }></Route>
                    <Route path='administrators' element={
                        <ManageUsers 
                            users={users}
                            sortId='admin' 
                        />
                    }></Route>
                    <Route path='import' element={
                        <ImportUsers 
                            oldUsers={oldUsers}
                            importedUsers={importedUsers}
                        />
                    }></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
            </div>
        </div>
    )
}

export default AdminUsers