import React, { useEffect, useRef, useState } from 'react'
import './portfolio-pages.styles.scss';
import { useParams } from 'react-router-dom';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import Modal, { ModalAlert } from '@/components/SHARED/modal/modal.component';
import InstructionModal from './instruction-modal.component';
import EvidenceCard from '../../evidence-card/evidence-card.component';
import UploadForm from '../../upload-form/upload-form.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component';
import { compareArrays } from '@/utils/compareArrays';
import { format } from 'date-fns';

const ContentPortfolioPage = ({ mcData, mcProgress, dirtyForms, saveDirtyStatus }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ sectionContent, setSectionContent ] = useState(null);
    const [ showInstructions, setShowInstructions ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ disabled, setDisabled ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ evalData, setEvalData ] = useState(null);
    const [ evNumber, setEvNumber ] = useState(null);
    const [ isDirty, setIsDirty ] = useState(true);
    const [ showUploadModal, setShowUploadModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ completeForm, setCompleteForm ] = useState(false);
    const { sectionId } = useParams();
    const dataRef = useRef({});
    const dirtyRef = useRef();

    useEffect(() => {
        // console.log('mcProgress' + ' ' + sectionId);
        // console.log(mcProgress);
    }, [mcProgress])

    useEffect(() => {
        if (!mcData || !sectionId || !mcProgress) return;
        getStoredData();
        setDetailStatus();
    }, [mcData, sectionId, mcProgress])

    // useEffect(() => {
    //     if (!dirtyForms) return;
    //     if (formData.status === 'declined' || (dirtyForms[sectionId] && Object.values(dirtyForms[sectionId]).includes(true))) {
    //         setIsDirty(true);
    //     } else {
    //         setIsDirty(false);
    //     }
    // }, [dirtyForms])

    useEffect(() => {
        let complete = true;
        for (let e in formData.exp) {
            if ((
                    e == 0 && 
                    !formData.exp[e] && 
                    !formData.upload[e]
                ) || 
                (
                    !formData.exp[e]  && 
                    formData.upload[e]
                ) || 
                (
                    formData.exp[e]  && 
                    !formData.upload[e]
                )
            ) {
                complete = false;
            }
        }
        setCompleteForm(complete)
    }, [formData])

    function setDetailStatus() {
        console.log('setting detail status')
        let content = [];
        for (let eo of Object.values(mcData.content)) {
            for (let c of Object.values(eo.comp)) {
                let newC = {...c};
                newC.eoName = eo.name;
                newC.eoDesc = eo.desc;
                content.push(newC);
                // const storedProg = JSON.parse(window.localStorage.getItem(`${newC.id}_${mcData.mc_key}`));
                // if (storedProg) {
                //     console.log(storedProg);
                //     console.log(mcProgress[`content_${newC.id}`]);
                //     if (
                //         !mcProgress[`content_${newC.id}`] || 
                //         (

                //             !compareArrays(storedProg.exp, mcProgress[`content_${newC.id}`].exp) ||
                //             !compareArrays(storedProg.upload, mcProgress[`content_${newC.id}`].upload)
                //         )
                //     ) {}
                //     saveDirtyStatus(newC.id, 0, true);
                // }
            }
        }
    
        setSectionContent(content.filter(c => c.id === sectionId)[0])
        if (mcProgress.approved || 
            mcProgress.pending || 
            mcProgress.submissionsRemaining === 0 || 
            (
                mcProgress[`content_${sectionId}`] && 
                mcProgress[`content_${sectionId}`].status === 'approved'
            )
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
        if (mcProgress[`eval_${sectionId}`]) {
            setEvalData({...mcProgress[`eval_${sectionId}`]});
        }
    }

    function getStoredData() {
        // console.log('getting stored data');
        const stored = JSON.parse(window.localStorage.getItem(`${sectionId}_${mcData.mc_key}`))
        if (stored) {
            dataRef.current = {...dataRef.current, ...stored};
        } else if (mcProgress[`content_${sectionId}`]) {
            dataRef.current = {...dataRef.current, ...mcProgress[`content_${sectionId}`]};
        } else {
            dataRef.current = {...dataRef.current, ...{'upload': ['','',''], 'exp': ['','','']}}
        }
        setFormData(dataRef.current);
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
        window.localStorage.setItem(`${sectionId}_${mcData.mc_key}`, JSON.stringify(dataRef.current));
        // setDetailStatus();
    }

    function chooseEvidenceFromModal(number) {
        setEvNumber(number);
        setShowUploadModal(true)
    }

    function saveExplanation(number, value) {
        let exps = [...dataRef.current.exp];
        exps[number] = value;
        saveData('exp', exps);
    }

    function saveArtifactChoice(id) {
        let uploads = dataRef.current.upload;
        uploads[evNumber] = id;
        saveData('upload', uploads);
        setEvNumber(null);
    }

    function removeEvidence(number) {
        let uploads = dataRef.current.upload;
        uploads[number] = '';
        saveData('upload', uploads);
    }

    async function saveEvidenceAndAnalysis() {
        setLoading(true);
        let tempData = {...dataRef.current};
        tempData.id = sectionId;
        tempData.timestamp = new Date().getTime();
        tempData.status = 'saved';
        const res = await UseCloudFunction('saveMcUserContent', {
            'profile': userProfile, 
            'mc_key': mcData.mc_key,
            'path': `content_${sectionId}`,
            'action': `${sectionId} evidence saved`,
            'content': tempData,
        })
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
            setLoading(false);
            return;
        }
        setLoading(false);
        dirtyRef.current = {'0': false, '1': false, '2': false};
        setIsDirty(dirtyRef.current);
    }

    return (
        <div className='portfolio-page-container'>
            {
                (sectionContent)
                ?
                <div>
                    <div className='g-list-item outcome'><b>{sectionContent.eoName}: </b>{sectionContent.eoDesc}</div>
                    <div className="g-space-2"></div>
                    <div className='title-box'>
                        <div className='section-title'><b>{sectionContent.id}</b> | {sectionContent.desc}</div>
                        {
                            (formData && formData.status && formData.status === 'approved')
                            ?
                            <div className='g-list-item approved'>Approved!</div>
                            :
                            (formData && formData.status && formData.status === 'declined') 
                            ?
                            <div className='g-list-item declined'>Declined</div>
                            : 
                            <button className='g-button small-btn' onClick={() => setShowInstructions(true)}>Instructions</button>
                        }
                    </div>
                    
                    {/* <hr /> */}
                    <div className="g-space-2"></div>
                    <div className='portfolio-page-content'>
                        <div className='g-list-item'>
                            <EvidenceCard 
                                mcProgress={{...mcProgress}} 
                                title='Evidence 1 (required)' 
                                id='ev1' 
                                hidden={false} 
                                disabled={disabled}
                                formData={{...formData}} 
                                evNumber={0} 
                                chooseEvidenceFromModal={chooseEvidenceFromModal}
                                saveExplanation={saveExplanation} 
                                removeEvidence={removeEvidence}
                                sectionId={sectionId}
                                saveDirtyStatus={saveDirtyStatus}
                            />
                            
                        </div>
                        {
                            (!formData.approved || (formData.approved && formData.exp[1])) &&
                            <>
                            <div className="g-space-2"></div>
                            <div className='g-list-item'>
                                <EvidenceCard 
                                    mcProgress={{...mcProgress}} 
                                    title='Evidence 2 (optional)' 
                                    id='ev2' 
                                    hidden={!formData.exp[1]} 
                                    disabled={disabled}
                                    formData={{...formData}} 
                                    evNumber={1} 
                                    chooseEvidenceFromModal={chooseEvidenceFromModal}
                                    saveExplanation={saveExplanation} 
                                    removeEvidence={removeEvidence}
                                    sectionId={sectionId}
                                    saveDirtyStatus={saveDirtyStatus}
                                />
                            </div>
                            </>
                        }
                        {
                            (!formData.approved || (formData.approved && formData.exp[2])) &&
                            <>
                            <div className="g-space-2"></div>
                            <div className='g-list-item'>
                                <EvidenceCard 
                                    mcProgress={{...mcProgress}} 
                                    title='Evidence 3 (optional)' 
                                    id='ev3' 
                                    hidden={!formData.exp[2]} 
                                    disabled={disabled}
                                    formData={formData} 
                                    evNumber={2} 
                                    chooseEvidenceFromModal={chooseEvidenceFromModal}
                                    saveExplanation={saveExplanation} 
                                    removeEvidence={removeEvidence}
                                    sectionId={sectionId}
                                    saveDirtyStatus={saveDirtyStatus}
                                />   
                            </div>
                            </>
                        }
                        <div className="g-space-1"></div>
                    </div>
                    <div className="g-space-2"></div>
                    {
                        (evalData && evalData.prev_comments) &&
                        <><div className='g-list-item prev-comment-item' >
                            <div className='title'>Evaluator's Comments:</div>
                            {
                                Object.values(evalData.prev_comments)
                                .sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
                                .map(e => (
                                    <div key={e.timestamp}>
                                        <div className="g-space-1"></div>
                                        <div>{format(new Date(e.timestamp), 'P')}: <b>{e.comment}</b></div>
                                    </div>
                                ))
                            }  
                        </div>
                        <div className="g-space-2"></div>
                        </>
                    }
                    
                    <div className='buttons'>
                        {
                            (loading)
                            ?
                            <button className='g-button disabled'>Loading</button>
                            :
                            (disabled)
                            ?
                            null
                            :
                            (!completeForm) 
                            ?
                            <button className='g-button disabled'>Incomplete Form</button>
                            :
                            (isDirty || completeForm)
                            ?
                            
                            // (!formData.upload[0] || !formData.exp[0])
                            // ?
                            // <button className='g-button disabled'>Form Incomplete</button>
                            // :
                            <button className='g-button primary' onClick={() => saveEvidenceAndAnalysis()}>Save Evidence, Location, and Analysis</button>
                            :
                            <button className='g-button disabled'>Nothing to Save</button>
                            
                        }
                    </div>
                </div>
                :
                <div className='center-col'><ThreeDotsLoader /></div>
            }
            <InstructionModal show={showInstructions} cancel={() => setShowInstructions(false)} />
            <Modal show={showUploadModal} cancel={() => setShowUploadModal(false)} closeButton={true} cls='wide' text='Choose an Existing Upload or Add a New One'>
                <UploadForm mcData={mcData} mcProgress={{...mcProgress}} cancel={() => setShowUploadModal(false)} setAlertMessage={setAlertMessage} chooseArtifact={saveArtifactChoice} />
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default ContentPortfolioPage