/**
 * @param {obj} progress 
 * 
 *  0 - no progress
 *  1 - material done
 *  2 - precheck pending
 *  3 - precheck redo
 *  4 - precheck approved
 *  5 - portfolio in progress
 *  6 - portfolio complete
 *  7 - portfolio pending
 *  8 - portfolio redo
 *  9 - portfolio approved
 *  10 - evaluation complete and badge earned
 */

export function findCurrentProgress(progress, mcData){
    let progNum = 0;
    if (progress && progress.content_materials) {
        progNum = 1;
    }
    if (progress && progress.content_precheck && progress.content_precheck.status === 'pending') {
        return 2;
    }
    if (progress && progress.content_precheck && progress.content_precheck.status === 'declined') {
        return 3;
    }
    if (progress && progress.content_precheck && progress.content_precheck.status === 'approved') {
        progNum = 4;
    } 
    let tempContent = [
        {
            'num': -2,
            'id': 'materials',
            'name': 'Viewed Required Micro-Credential Materials'
        },
        {
            'num': -1,
            'id': 'precheck',
            'name': 'Precheck Artifact and Self-Analysis'
        },
        {
            'num': 0,
            'id': 'overview',
            'name': 'Overview Narrative'
        }
    ]
    for (let eo of Object.values(mcData.content).sort((a,b) => a.num > b.num ? 1 : -1)) {
        for (let co of Object.values(eo.comp)) {
            tempContent.push(co);
        }
    }
    tempContent.push({
        'num': Object.values(mcData.content).length + 1,
        'id': 'closing',
        'name': 'Closing Reflection'
    })
    let completeCount = 0;
    for (let key of Object.keys(progress)) {
        if (key.includes('content_')) {
            completeCount++;
        }
    }
    if (completeCount > 3 && completeCount < tempContent.length) {
        progNum = 5;
    }
    if (completeCount === tempContent.length) {
        progNum = 6;
    }
    if (progress && progress.submitted) {
        const lastSubmit =  Object.values(progress.submitted).sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)[0];
        if (lastSubmit.retStatus === 'pending') {
            progNum = 7;
        } else if (lastSubmit.retStatus === 'declined' && completeCount < tempContent.length) {
            progNum = 5;
        } else if (lastSubmit.retStatus === 'declined') {
            progNum = 8;
        } else if (lastSubmit.retStatus === 'approved') {
            progNum = 9;
        }
    }
    if (progress && progress.status === 'complete') {
        progNum = 10;
    }

    return progNum;
}