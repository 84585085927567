import React, { useEffect, useState } from 'react'
import './loading.styles.scss'
import { useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { setUserProfile } from '@/state/slices/userSlice';

// import GetProfileData from '@/utils/getProfile'

import Loader from '@/components/SHARED/loader/loader.comp';
import { ModalAlert } from '@/components/SHARED/modal/modal.component';


const LoadingPage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const currentUser = useSelector(state => state.userData.currentUser);
    const [ alertMessage, setAlertMessage ] = useState(null);
	const navigate = useNavigate()
	const dispatch = useDispatch()

 	useEffect(() => {
        if (!currentUser) {return;}
        getUserProfile();
	}, [currentUser])

    async function getUserProfile() {
        // const profile = await 
    }
	  
	return (
		<div className='loading-page'>
		    <div>
				<Loader />
			</div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
		</div>
	)
}

export default LoadingPage