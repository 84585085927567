import React, { useEffect, useRef, useState } from 'react'
import './eval-sidebar.styles.scss';
import { useNavigate } from 'react-router-dom';
import { checkStatus } from '../determineStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EvalSidebar = ({ progress, currentData, activeId, content, dirtyStatus }) => {

    const [ status, setStatus ] = useState({})
    const navigate = useNavigate();
    const statusRef = useRef();

    useEffect(() => {
        // console.log(progress);
        if (!progress || !currentData || !dirtyStatus | !content) return;
        // console.log(progress);
        let idArr = ['precheck', 'overview', 'closing']
        for (let e of content) {
            if (!e.comp) continue;
            for (let c of Object.values(e.comp)) {
                idArr.push(c.id)
            }
        }
        // console.log(idArr);
        for (let id of idArr) {
            const status = checkStatus(
                id, 
                {
                    'progress': progress, 
                    'currentData': currentData, 
                    'dirtyStatus': dirtyStatus
                }
            )
            statusRef.current = {...statusRef.current, ...{[id]: status}}
            setStatus(statusRef.current);
        }
    }, [progress, currentData, content, dirtyStatus]);

    return (
        <div className='eval-sidebar-container'>
            <div 
                className={`menu-item ${
                    activeId === 'summary' 
                    ? 'active' 
                    : ''
                }`} 
                onClick={() => navigate(`/evaluate/${currentData.mcData.mc_key}_${currentData.user.cst_key}`)}
            >
                Summary
            </div>
            <div 
                className={`menu-item ${
                    activeId === 'precheck' 
                    ? 'active' 
                    : ''
                }`} 
                onClick={() => navigate('precheck')}
            >
                Precheck Upload
                {
                    (status['precheck']) &&
                    <div className='status'>
                        <FontAwesomeIcon 
                            icon={status['precheck'] && status['precheck'].icon} 
                            className={status['precheck'] && status['precheck'].class} 
                            title={status['precheck'] && status['precheck'].title} 
                        />
                    </div>
                }
            </div>
            <div className={`menu-item ${activeId === 'overview' ? 'active' : ''}`} onClick={() => navigate('overview')}>
                Overview Questions
                {
                    (status['overview']) &&
                    <div className='status'>
                        <FontAwesomeIcon 
                            icon={status['overview'] && status['overview'].icon} 
                            className={status['overview'] && status['overview'].class} 
                            title={status['overview'] && status['overview'].title} 
                        />
                    </div>
                }
            </div>
            {
                (content) &&
                content.sort((a,b) => a.num > b.num ? 1 : -1)
                .map(e => {
                    if (e.comp) {
                        return (
                            <div key={`${e.id}-menu`} className='section' >
                                <div className='menu-header' id={e.id}>
                                        {e.name}
                                </div>
                                {
                                    Object.values(e.comp).sort((a,b) => a.id > b.id ? 1 : -1)
                                    .map(c => (
                                        <div key={c.id} className={`menu-item ${activeId === c.id ? 'active' : ''}`} onClick={() => navigate(c.id)}>
                                            {c.name}
                                            {
                                                (status[c.id]) &&
                                                <div className='status'>
                                                    <FontAwesomeIcon 
                                                        icon={status[c.id] && status[c.id].icon} 
                                                        className={status[c.id] && status[c.id].class} 
                                                        title={status[c.id] && status[c.id].title} 
                                                    />
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                })
            }
            
            <div className={`menu-item ${activeId === 'closing' ? 'active' : ''}`} onClick={() => navigate('closing')}>
                Closing Reflection
                <div className='status'>
                    {
                        (status['closing']) &&
                        <div className='status'>
                            <FontAwesomeIcon 
                                icon={status['closing'] && status['closing'].icon} 
                                className={status['closing'] && status['closing'].class} 
                                title={status['closing'] && status['closing'].title} 
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EvalSidebar