import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentUser: null,
    userProfile: null,
    dbData: null,
}

export const revertAll = createAction('REVERT_ALL')

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setUserProfile: (state, action) => {
            state.userProfile = action.payload
        },
        setDbData: (state, action) => {
            state.dbData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { 
    setCurrentUser, 
    setUserProfile,
    setDbData,
} = userSlice.actions

export default userSlice.reducer