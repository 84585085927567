import React, { useEffect, useState } from 'react';
import './portfolio-status.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faRightLong, faUpload } from '@fortawesome/free-solid-svg-icons';
import { findCurrentProgress } from '@/utils/findCurrentProgress';
import { format } from 'date-fns';
import Joyride from 'react-joyride';

const PortfolioStatusBar = ({ mcData, mcProgress, progressValue, setViewUploads, submitPortfolio }) => {

    const [ progNumber, setProgNumber ] = useState(0);
    const [ steps, setSteps ] = useState([]);
    let timeout;
    let stillDeclinedCount = 0;
    const styles = {
        options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: 'var(--primary)',
            textColor: 'var(--primary)',
        },
    }

    useEffect(() => {
        if (!mcData || !mcProgress) return;
        const progNumber = findCurrentProgress(mcProgress, mcData);
        setProgNumber(progNumber);
        console.log(progNumber);
        Object.keys(mcProgress).map(p => {
            p.includes('content_') && mcProgress[p].status === 'declined' ? stillDeclinedCount++ : null
        })
        console.log(stillDeclinedCount);
        if (progNumber === 6 || (progNumber === 8 && stillDeclinedCount === 0)) {
            timeout = setTimeout(() => {
                const stepArr = [
                    {
                        target: '.submit-button',
                        content: 'Your portfolio is complete! Click this button to submit it for evaluation.',
                        disableBeacon: true,
                        placement: 'left',
                    }
                ]
                setSteps([...stepArr])
            }, 500);
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        }
    }, [mcData, mcProgress])

    function clearJoyride(e) {
        console.log(e);
        // setSteps([]);
    }

    /*
    * If your steps are not dynamic you can use a simple array.
    * Otherwise you can set it as a state inside your component.
    */
    
    
    return (
        <div>
            <div className='portfolio-progress-bar'>
                Portfolio Progress:
                <div className='progress-container'>
                    <div className="progress">
                        <div className="progress-bar"  aria-valuenow={progressValue} min="0" max="100" >
                        </div>
                    </div>
                    <div className='meta'>{progressValue}%</div>
                </div>
                <div></div>
                {
                    (mcProgress) &&
                    <div className='button-row'>
                        <div className='left-buttons'>
                            <button className='g-button small-btn' onClick={() => setViewUploads(true)}>View Uploads<FontAwesomeIcon icon={faUpload} /></button>
                            {/* <button className='g-button small-btn' onClick={() => setShowProgress(true)}>Progress<FontAwesomeIcon icon={faList} /></button> */}
                        </div>
                        <div className='row meta'>
                            Attempts Remaining: {mcProgress.submissionsRemaining ?? 2}
                        </div>
                        {
                            (mcProgress.pending)
                            ?
                            <div className='g-list-item pending'>
                                Submitted on {format(new Date(Object.values(mcProgress.submitted)[Object.values(mcProgress.submitted).length - 1].timestamp), 'P')}
                            </div>
                            :
                            (mcProgress.approved)
                            ?
                            <div className='g-list-item approved'>
                                Approved on {format(new Date(Object.values(mcProgress.submitted)[Object.values(mcProgress.submitted).length - 1].retTime), 'P')}
                            </div>
                            :
                            (mcProgress.submissionsRemaining === 0) 
                            ?
                            <div className='g-list-item'>
                                No Attempts Remaining
                            </div>
                            :
                            <button className={`g-button submit-button ${
                                (progNumber < 6) ? 'disabled small-btn' : 'primary'
                            }`} onClick={() => submitPortfolio()}>
                                <div className='row'>
                                    Submit Final Portfolio
                                    <FontAwesomeIcon icon={faRightLong} />
                                </div>
                            </button>   
                        }
                    </div>
                }
            </div>
            {
                (steps.length > 0) &&
                <Joyride steps={steps} scrollToFirstStep={true} styles={styles} debug={true} callback={clearJoyride}/>
            }
        </div>
    )
}

export default PortfolioStatusBar