import React, { useEffect } from 'react'
import './eval-reflection-box.styles.scss';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const EvalReflectionBox = ({ data, onClick }) => {

    return (
        <div className='eval-reflection-box-container'>
            {
                (data) &&
                <div className='eval-reflection-box-content g-list-item'>
                    {/* <div className='eval-title'>Reflection and Artifact {index}</div> */}
                    {/* <div className="g-space-1"></div> */}
                    <div className='eval-upload'>
                        <b>Evidence:</b>
                        <EvidenceButton evidenceData={data.upload} onClick={onClick ? () => onClick() : () => null} />
                    </div>
                    <div className="g-space-2"></div> 
                    <div className='eval-explanation'>
                        <b>Explanation:</b>
                        {data.exp}
                    </div>
                </div>
            }
        </div>
    )
}

export default EvalReflectionBox