import { faCircleCheck, faCircle as faCircleClosed, faExclamationCircle, faSave, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

export function checkStatus(path, data) {
    let submitted = false;
    if (path === 'precheck' && data.progress.content_precheck && data.progress.content_precheck.status === 'pending') {
        submitted = true;
    } else if (data.progress.submitted) {
        const subArr = Object.values(data.progress.submitted)
        .sort((a,b) => a.timestamp < b.timestamp ? 1 : -1)
        const lastSub = subArr[0];
        // console.log(lastSub);
        if (!lastSub.retTime) {
            submitted = true;
        }
    }
    const prog = data.progress;
    const dirtyStatus = data.dirtyStatus;
    if (path === 'materials') {
        if (prog.content_materials && prog.content_materials.timestamp) {
            return {'icon': faCircleCheck, 'class': 'done', 'title': 'Completed'}
        } else {
            return {'icon': faCircle, 'class': 'undone', 'title': 'Incomplete'}
        }
    } 


    else if (prog[`content_${path}`]) {
        

        if (dirtyStatus[path]) {
            return {'icon': faSave, 'class': 'pending', 'title': 'Unsaved Changes'}
        } 
        if (prog[`content_${path}`].status === 'pending') {
            return {'icon': faCircleClosed, 'class': 'pending', 'title': 'Pending'}
        }
        if (prog[`content_${path}`].status === 'approved') {
            return {'icon': faCircleCheck, 'class': 'done', 'title': 'Approved'}
        } 
        if (prog[`content_${path}`].status === 'declined') {
            return {'icon': faCircleXmark, 'class': 'declined', 'title': 'Declined' }
        } 
        if (prog[`content_${path}`].status) {
            if (submitted && prog[`eval_${path}`]) {
                const ev = prog[`eval_${path}`];
                if (!ev.completed || !ev.explained || !ev.located) {
                    return {'icon': faCircleXmark, 'class': 'declined', 'title': 'Declined' }
                } else {
                    return {'icon': faCircleCheck, 'class': 'done', 'title': 'Approved'}
                }
            } else if (submitted) {
                return {'icon': faExclamationCircle, 'class': 'pending', 'title': 'Submitted'}
            } else {
                return {'icon': faCircle, 'class': 'in_progress', 'title': 'In Progress'}
            }
        } 
    }

    return {'icon': faCircle, 'class': 'undone', 'title': 'Undone'};
}