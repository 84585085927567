import React, { useRef, useState } from 'react';
import './bug-modal.styles.scss';

import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { UseCloudFunction } from '@/utils/firebase.utils';
import Modal, { ModalAlert } from '@/components/SHARED/modal/modal.component';
import { resizeImage } from '@/utils/resizeImage';

const BugModal = ({ cancel, show }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const currentOrg = useSelector(state => state.contentData.currentOrg);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const dataRef = useRef();
    const imageRef = useRef();
    const storage = getStorage();

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current)
    }

    async function handleSubmit() {
        setLoading(true);
        const now = new Date().getTime();
        const file = document.getElementById('bug-image-upload').files[0]
        let newURL = '';
        if (file) {
            console.log(file.size)
            const resizedImage = await resizeImage(file)
            console.log(resizedImage.size)
            const bugStorageRef = ref(storage, `uploaded-bug-images/${now}-${file.name}`)
			const metadata = { contentType: resizedImage.type }
			await uploadBytes(bugStorageRef, resizedImage, metadata)
			.then(snap => console.log(snap))
			newURL = await getDownloadURL(bugStorageRef)
			console.log(newURL)
        }
        const obj = {...dataRef.current}
        obj.imageURL = newURL;
        obj.email = profile.email;
        obj.cst_key = profile.cst_key;
        obj.fName = profile.fName;
        obj.lName = profile.lName;
        obj.status = 'active';
        obj.ts = now;
        obj.id = now + '_bug';
        console.log(obj);
        const res = await UseCloudFunction('saveBugReport', {'obj': obj})
        console.log(res)
        if (res.success) {
            dataRef.current = {}
            setFormData(dataRef.current)
            document.getElementById('bug-image-upload').value = null;
            cancel();
            setAlertMessage('Thank you for reporting this bug. Someone will be in contact with you soon!');
        } else {
            setAlertMessage('Something went wrong. Please try again later. ' + res.error);
        }
        setLoading(false);
	}

    return (
        <div className='bug-modal'>
            <Modal cancel={cancel} show={show} text={`Report a Bug`} closeButton={true} >
                <div className='bug-report-container'>
                    <p>Thanks for reporting this bug. Your user information has already been collected, but please add details below to describe the issue. You can also upload a screenshot if that will help clarify the issue. Thanks for your help!</p>
                    <div className='field'>
                        <label>Give this bug a short title<span className='required'>*</span></label>
                        <input type='text' value={formData.title ?? ''} onChange={(e) => saveData('title', e.target.value)} />
                    </div>
                    <div className='field'>
                        <label>Describe the issue<span className='required'>*</span></label>
                        <textarea rows="4" value={formData.desc ?? ''} onChange={(e) => saveData('desc', e.target.value)}></textarea>
                    </div>
                    <div className='g-list-item upload'>
                        <div style={{cursor: 'pointer'}}>
					        <input id='bug-image-upload' type="file" accept="image/*"   />
                            {/* <button className='g-button'>Upload Screenshot</button> */}
                        </div>
                        {/* <span>Nothing uploaded yet...</span> */}
                    </div>
                    <hr />
                    <div className='buttons'>
                        {
                            (!loading)
                            ? <button className='g-button primary' onClick={() => handleSubmit()}>Submit Report</button>
                            : <button className='g-button primary static'><div data-uk-spinner='ratio: .5'></div></button>
                        }
                    </div>
                    <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
                </div>
            </Modal>
        </div>
    )
}

export default BugModal;