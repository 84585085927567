import { createSlice } from '@reduxjs/toolkit'
import { revertAll } from './userSlice'

const initialState = {
    currentMc: null,
    mcList: null,
    users: null,
    tempMc: null,
    oldMcs: null,
    oldUsers: null,
    importedUsers: null,
    importedMcs: null,
    myMcList: null,
    myProgress: null,
    evaluatingList: null,
    screenSize: null,
}

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setCurrentMc: (state, action) => {
            state.currentMc = action.payload
        },
        setMcList: (state, action) => {
            state.mcList = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setTempMc: (state, action) => {
            state.tempMc = action.payload
        },
        setOldMcs: (state, action) => {
            state.oldMcs = action.payload
        },
        setOldUsers: (state, action) => {
            state.oldUsers = action.payload
        },
        setImportedUsers: (state, action) => {
            state.importedUsers = action.payload
        },
        setImportedMcs: (state, action) => {
            state.importedMcs = action.payload
        },
        setMyMcList: (state, action) => {
            state.myMcList = action.payload
        },
        setMyProgress: (state, action) => {
            state.myProgress = action.payload
        },
        setEvaluatingList: (state, action) => {
            state.evaluatingList = action.payload
        },
        setScreenSize: (state, action) => {
            state.screenSize = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    setCurrentMc,
    setMcList,
    setUsers,
    setTempMc,
    setOldMcs,
    setOldUsers,
    setImportedUsers,
    setImportedMcs,
    setMyMcList,
    setMyProgress,
    setEvaluatingList,
    setScreenSize,
} = contentSlice.actions

export default contentSlice.reducer