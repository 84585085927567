import React, { useState } from 'react';
import './layout.styles.scss';

import { useLocation } from 'react-router-dom';
import HeadBar from './head-bar/head-bar.component';
import SidebarLeftContainer from './sidebar-left/sidebar-container.component';
import { Desktop, LargeTablet, Mobile, Tablet } from '@/utils/responsiveQueries';

const Layout = ({ children, orgId }) => {

    const location = useLocation();

    return (
        <div>
            <Mobile>
                {/* <HeaderMobile /> */}
                <div className='mobile-content-container'>
                    {children}
                </div>
            </Mobile>

            <Tablet>
                <HeadBar />
                <div className='desktop-container'>
                    <div className='sidebar'>
                        {
                            (location.pathname.includes('/manage')) 
                            ?
                            <SidebarLeftContainer orgId={orgId} />
                            :
                            <SidebarLeftContainer />
                        }
                    </div>
                    <div className='content-container'>
                        <div id='scroll-point'></div>
                        {children}
                    </div>
                </div>
            </Tablet>

            <LargeTablet>
                <HeadBar />
                <div className='desktop-container'>
                    <div className='sidebar'>
                        {
                            (location.pathname.includes('/manage')) 
                            ?
                            <SidebarLeftContainer orgId={orgId} />
                            :
                            <SidebarLeftContainer />
                        }
                    </div>
                    <div className='content-container'>
                        <div id='scroll-point'></div>
                        {children}
                    </div>
                </div>
            </LargeTablet>


            <Desktop>
                <HeadBar />
                <div className='desktop-container'>
                    <div className='sidebar'>
                        {
                            (location.pathname.includes('/manage')) 
                            ?
                            <SidebarLeftContainer orgId={orgId} />
                            :
                            <SidebarLeftContainer />
                        }
                    </div>
                    <div className='content-container'>
                        <div id='scroll-point'></div>
                        {children}
                    </div>
                </div>
            </Desktop>

            

            
            
        </div>
    )
}

export default Layout