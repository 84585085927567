import React from 'react'
import './title-card.styles.scss';

const TitleCard = ({ mcData, title }) => {

    return (
        <div className='mc-title-card g-card'>
            {
                (mcData) &&
                <>
                <div className='page-title'>
                    {title}
                </div>
                <div className='mc-info'>
                    <div className='mc-title'>{mcData.name}</div>
                    <img className='mc-icon' src={mcData.imageUrl} />
                </div>
                </>
            }
        </div>
    )
}

export default TitleCard