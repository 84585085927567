import React from 'react'
import './eval-comment-box.styles.scss';

const EvalCommentBox = ({ data, saveData }) => {
    return (
        <div className='eval-comment-box'>
            <div className='field'>
                <label>Comments (required)</label>
                <textarea rows="3" value={data.comment ?? ''} onChange={(e) => saveData('comment', e.target.value)}></textarea>
            </div>
        </div>
    )
}

export default EvalCommentBox