import React from 'react';
import './eval-toggles.styles.scss';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';

const EvalToggles = ({ data, saveData }) => {
    return (
        <div className='eval-toggles'>
            <div className='field with-toggle'>
                <ToggleInput contentKey={`located`} checked={data.located} saveData={saveData} />
                Provided evidence location within artifact
            </div>
            <div className='field with-toggle'>
                <ToggleInput contentKey={`explained`} checked={data.explained} saveData={saveData} />
                Explained how artifact demonstrates detail
            </div>
            <div className='field with-toggle'>
                <ToggleInput contentKey={`completed`} checked={data.completed} saveData={saveData} />
                Artifact completely demonstrates detail
            </div>
        </div>
    )
}

export default EvalToggles