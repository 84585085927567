import React from 'react';
import './sidebar-container.styles.scss';
import { useSelector } from 'react-redux';
import SidebarLeft from './sidebar-left.component';

const SidebarLeftContainer = ({ orgId }) => {

    const myOrgs = useSelector(state => state.contentData.myOrgs);
    
    return (
        <div className='sidebar-left-container'>
            <div className=''>
                <SidebarLeft orgId={orgId} />
            </div>
        </div>
    )
}

export default SidebarLeftContainer