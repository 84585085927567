export function compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let a in arr1) {
        if (arr1[a] !== arr2[a]) {
            return false;
        }
    }
    return true;
}