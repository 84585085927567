import React, { useEffect, useLayoutEffect, useState } from 'react'
import './head-bar.styles.scss'

import { useSelector } from 'react-redux';
import UserDropdown from './user-dropdown.component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserIcon from '../user-icon/user-icon.component';
import long_logo from '@/assets/eln-logo-full-white.png'

const HeadBar = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="head-bar-container" id='head-bar'>
            <div className="logo-container">
                    {/* <div className="g-card"> */}
                    
                    <img src={long_logo} alt="Logo" className="logo"  onClick={() => navigate('/home')} />
                    <div className='menu-spacer'></div>
                    <div className='header-text'>Micro-Credential Portal</div>
                {/* </div> */}
                
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        {/* <div className='head-menu-item' onClick={() => navigate('/coach')}>
                            Coach Home
                        </div>
                        <div className='menu-spacer'></div>
                        <div className='head-menu-item' onClick={() => navigate('/manage')}>
                            Manage Org
                        </div>
                        <div className='menu-spacer'></div>
                        <div className='head-menu-item' onClick={() => setShowMyOrgs(true)}>
                            Switch Organization
                        </div>
                        <div className='menu-spacer'></div> */}
                        {
                            (userProfile && userProfile.role.includes('superUser')) &&
                            <>    
                                <Link to='/admin'>
                                    <button className='g-button static head-menu-item'>
                                        Admin
                                    </button>
                                    
                                </Link>
                                
                            </>
                        }
                    </div>
                    <div className="menu-spacer"></div>
                    <div className='head-user-info'>
                        {
                            (currentUser && userProfile) &&
                            <>
                                
                                
                                {/* <div className='user-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                    <UserIcon profile={userProfile} reverse={true} />
                                </div> */}
                                <button className='g-button static head-menu-item' onClick={() => setShowDropdown(!showDropdown)}>
                                    {userProfile.fName} {userProfile.lName}
                                    <UserIcon profile={userProfile} reverse={true} />
                                </button>

                            </>
                            
                        }
                    </div>
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>
    )
}

export default HeadBar