import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './mc-list.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faGear, faPen, faPlusCircle, faRefresh, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenuItems } from '../admin-layout/side-menu/toggle-items'
import { UseCloudFunction } from '@/utils/firebase.utils'
import { setMcList, setOldMcs, setTempMc } from '@/state/slices/contentSlice'
import { format } from 'date-fns'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component'

const McList = ({ mcList, regs, nfeRegs, importedMcs }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ oldMcs, setOldMcs ] = useState(null);
    const [ refreshing, setRefreshing ] = useState(false);
    const [ regCounts, setRegCounts ] = useState({});
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getOldMcs();
    }, [])

    useEffect(() => {
        if (!regs) return;
        let regCtObj = {}
        for (let mc of Object.values(regs)) {
            let partArr = [];
            let evalArr = [];
            for (let cKey of Object.keys(mc.roles)) {
                if (mc.roles[cKey].includes('part')) {
                    partArr.push(cKey);
                }
                if (mc.roles[cKey].includes('eval')) {
                    evalArr.push(cKey);
                }
            }
            regCtObj[mc.mc_key] = {
                "part": partArr,
                "eval": evalArr,
            }
            setRegCounts(regCtObj);
        }
    }, [regs])

    useEffect(() => {
        if (oldMcs && location.pathname.includes('logout')) {return;}        
    }, [oldMcs])

    async function getOldMcs() {
        const data = await UseCloudFunction('getDataFromOldDatabase', {'url': 'https://us-central1-ipa-microcredentials.cloudfunctions.net/app/api/get-mcs', 'cst_key': userProfile.id});
        setOldMcs(data);
    }

    function convertMc(mc) {
        dispatch(setTempMc(mc));
        navigate('convert');
    }

    return (
        <div className='mc-list'>
            <div className='section-title with-icon'>
                Current Micro-Credentials
                <div className='multiple-icons'>
                    <Link to='createmc'><button className='g-button text'><FontAwesomeIcon icon={faPlusCircle} className='icon' title='Create New Micro-Credential'></FontAwesomeIcon></button></Link>
                </div>
            </div> 
            {
                (!refreshing) 
                ?
                <table className='g-table'>
                    <thead>
                        <tr>
                            <th width="45%">Title</th>
                            <th>Created</th>
                            <th>Part</th>
                            <th>Eval</th>
                            <th>Reg</th>
                            <th className='icon-td'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (mcList) &&
                            Object.values(mcList).filter(a => a.nfe_code && a.status)
                            .sort((a,b) => a.name > b.name ? 1 : -1)
                            .map(mc => (
                                <tr key={mc.mc_key}>
                                    <td>
                                        <b>{mc.name}</b><br />
                                        <span className='meta'>{mc.mc_key}</span>
                                    </td>
                                    <td>{mc.ts_created ? format(new Date(Number(mc.ts_created)), 'P') : ''}</td>
                                    <td>{regCounts[mc.mc_key] ? regCounts[mc.mc_key].part.length : 0}</td>
                                    <td>{regCounts[mc.mc_key] ? regCounts[mc.mc_key].eval.length : 0}</td>
                                    <td>{nfeRegs[mc.mc_key] ? nfeRegs[mc.mc_key].count : 0}</td>
                                    <td className='icon-td'>
                                        <div className='buttons'>
                                            <Link to={`editmc/${mc.mc_key}`}>
                                                <button className='g-button small-btn'><FontAwesomeIcon icon={faPen} /></button>
                                            </Link>
                                            <button className='g-button small-btn' title='Manage MC Data and Users' onClick={() => navigate(`viewmcdata/${mc.mc_key}`)}><FontAwesomeIcon icon={faUsers} /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        
                    </tbody>
                </table>
                :
                <>
                    <hr />
                    <h3 className='center'>Loading Micro-Credentials...</h3>
                </>
            }
            <div className='g-space-40'></div>
            <div className='section-title with-icon'>
                Micro-Credentials to Convert
                <div className='multiple-icons'>
                </div>
            </div> 
            {
                (!refreshing) 
                ?
                <table className='g-table'>
                    <thead>
                        <tr>
                            <th width="45%">Title</th>
                            <th>Created</th>
                            <th>Participants</th>
                            <th>Evaluators</th>
                            <th className='icon-td'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (oldMcs && mcList) &&
                            Object.values(oldMcs).filter(a => !importedMcs.list.includes(a.id))
                            .sort((a,b) => a.name > b.name ? 1 : -1)
                            .map(mc => (
                                <tr key={mc.id}>
                                    <td>{mc.name}</td>
                                    <td>{mc.ts ? format(new Date(Number(mc.ts)), 'P') : ''}</td>
                                    <td>{mc.parts ? Object.values(mc.parts).length : 0}</td>
                                    <td>{mc.evals ? Object.values(mc.evals).length : 0}</td>
                                    <td className='icon-td'>
                                        <button className='g-button small-btn' onClick={() => convertMc(mc)}>Convert</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                :
                <>
                    <hr />
                    <h3 className='center'>Loading Micro-Credentials...</h3>
                </>
            }
        </div>
    )
}

export default McList