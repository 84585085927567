import { ref, query as dbQuery, orderByChild, equalTo, limitToLast, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { and, collection, doc, query as fbQuery, getDoc, getDocs, onSnapshot, or, startAfter, where } from "firebase/firestore";
import { db, rtDb } from "@/utils/firebase.utils";

export function getMcsFromDb(e) {
    let mcRef = fbQuery(collection(db, `mcs`))

    if (e.status) {
        mcRef = fbQuery(collection(db, `mcs`), where('status', '==', e.status));
    }
    const unsubscribe = onSnapshot(mcRef, docs => {
        docs.forEach(doc => {
            const data = doc.data();
            e.callback(data);
        });
    })
    e.handleListener(unsubscribe);
}

export async function getIndividualMc(e) {
    let mcRef = doc(db, `mcs/${e.mc_key}`);
    const mcDoc = await getDoc(mcRef);
    return mcDoc.data();
    
}

export function getUsersFromDb(e) {
    let userRef;

    if (e.status) {
        userRef = fbQuery(collection(db, 'users'), where('status', '==', e.status));
    }

    const unsubscribe = onSnapshot(userRef, docs => {
        let mcObj = {}
        docs.forEach(doc => {
            const data = doc.data();
            mcObj[doc.id] = data;
        });
        e.callback(mcObj);
    })
    e.handleListener(unsubscribe);
}

export function getImportedUsersFromDb(e) {
    let listRef = doc(db, 'utils/importedUsers');
    const unsubscribe = onSnapshot(listRef, doc => {
        e.callback(doc.data());
    })
    e.handleListener(unsubscribe);
}

export function getImportedMcsFromDb(e) {
    let listRef = doc(db, 'utils/importedMcs');
    const unsubscribe = onSnapshot(listRef, doc => {
        e.callback(doc.data());
    })
    e.handleListener(unsubscribe);
}

export function getUserMcsFromDb(e) {
    let regListRef;

    if (e.cst_key && e.mc_key) {
        regListRef = fbQuery(collection(db, 'regs'), and(where('list', 'array-contains', e.cst_key), where('mc_key', '==', e.mc_key)))
    } else if (e.cst_key && e.status) {
        regListRef = fbQuery(collection(db, 'regs'), and(where('list', 'array-contains', e.cst_key), where('status', '==', e.status)))
    } else if (e.cst_key) {
        regListRef = fbQuery(collection(db, 'regs'), where('list', 'array-contains', e.cst_key))
    }

    const unsubscribe = onSnapshot(regListRef, docs => {
        let mcObj = {};
        let count = 0;
        if (docs.size === 0) {
            e.callback({});
            return;
        }
        docs.forEach(async regDoc => {
            const data = regDoc.data();
            // get MC data
            const mcRef = doc(db, `mcs/${data.mc_key}`);
            const mcDoc = await getDoc(mcRef);
            let newMcDoc = mcDoc.data();
            newMcDoc.roles = data.roles[e.cst_key];
            
            // // get user progress
            // const progressRef = doc(db, `progress/${data.mc_key}_${e.cst_key}`)
            // const progressDoc = await getDoc(progressRef);
            // let progress = null;
            // if (progressDoc.exists) {
            //     progress = progressDoc.data()
            // }
            // if (data.imported.includes(e.cst_key) && !progress) {
            //     newMcDoc.imported = true;
            // }
            mcObj[data.mc_key] = {'mcData': newMcDoc};
            count++;
            if (count === docs.size) {
                e.callback(mcObj);
            }
        });
    })
    e.handleListener(unsubscribe);
}

export function getMcDataFromDb(e) {
    let mcRef;

    if (e.mc_key) {
        mcRef = doc(db, `mcs/${e.mc_key}`);
    }
    const unsubscribe = onSnapshot(mcRef, doc => {
        e.callback(doc.data());
    })
    e.handleListener(unsubscribe);
}

export function getProgressFromDb(e) {
    let progressRef;

    if (e.cst_key && e.mc_key) {
        progressRef = doc(db, `progress/${e.mc_key}_${e.cst_key}`);
    }

    const unsubscribe = onSnapshot(progressRef, doc => {
        e.callback(doc.data());
    })
    e.handleListener(unsubscribe);
}

export function getMcRegsFromDb(e) {
    let regsRef;

    if (e.mc_key) {
        regsRef = doc(db, `regs/${e.mc_key}`);
    }

    const unsubscribe = onSnapshot(regsRef, async document => {
        const regsData = document.data();
        // let fullObj = {
        //     'regs': regsData,
        //     // 'progress': {},
        //     'users': {},
        // }
        // for (let r of regsData.list) {
        //     const userDocRef = doc(db, `users/${r}`);
        //     const userDoc = await getDoc(userDocRef);
        //     if (userDoc.exists() && regsData.roles[r].includes('part')) {
        //         fullObj.users[r] = userDoc.data();
        //     }
        //     // const progressDocRef = doc(db, `progress/${e.mc_key}_${r}`);
        //     // const progDoc = await getDoc(progressDocRef);
        //     // if (progDoc.data()) {
        //     //     fullObj.progress[`${e.mc_key}_${r}`] = progDoc.data();
        //     // }    
        // }
        e.callback(regsData);
    })
    e.handleListener(unsubscribe);
}


export function getEarnedBadgesFromDb(e) {
    let badgesRef;

    if (e.cst_key) {
        badgesRef = fbQuery(collection(db, 'earnedBadges'), and(where('cst_key', '==', e.cst_key), where('status', '==', 'active')));
    }

    const unsubscribe = onSnapshot(badgesRef, docs => {
        docs.forEach(async docSnap => {
            const data = docSnap.data();
            e.callback(data);
        })
    })
    e.handleListener(unsubscribe);
}

export async function getUniqueDocFromDb(e) {
    let ref = doc(db, e.path);
    
    if (e.once) {
        const doc = await getDoc(ref);
        e.callback(doc.data());
        return;
    }
    
    const unsubscribe = onSnapshot(ref, doc => {
        // console.log(doc.data());
        e.callback(doc.data());
    })
    e.handleListener(unsubscribe);
}

export function getCollectionFromDb(e) {
    let queryRef = fbQuery(collection(db, e.path))

    if (e.status) {
        queryRef = fbQuery(collection(db, e.path), where('status', '==', e.status));
    }
    const unsubscribe = onSnapshot(queryRef, docs => {
        docs.forEach(doc => {
            e.callback(doc.data(), doc.id);
        })
    })
    e.handleListener(unsubscribe);
}