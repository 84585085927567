import React from 'react'
import './loader.styles.scss'

const Loader = () => {
    return (
        <div className='box-loader'>
            <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader