import React from 'react'
import "./init";
import ReactDOM from 'react-dom/client'
import Root from './App.jsx'
import './index.css';
// import { registerSW } from "virtual:pwa-register";

import { Provider } from 'react-redux'
import { store, persistor } from './state/store'
import { PersistGate } from 'redux-persist/integration/react'

import { Route, BrowserRouter as Router, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import ReactGA from "react-ga4";


// add this to prompt for a refresh
// const updateSW = registerSW({
//     onNeedRefresh() {
//         if (confirm("New content available. Reload?")) {
//             updateSW(true);
//         }
//     },
// });

const router = createBrowserRouter(
[
    {
        path: '*',
        Component: Root
    },
]
// createRoutesFromElements(
//     <Route path='/' element={<App />}></Route>
// ) 
);

ReactGA.initialize("_YOUR_GA4_CODE");

ReactDOM.createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
            {/* <Router>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </Router> */}
        </Provider>
    // </React.StrictMode>,
)

const SendAnalytics = ()=> {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
}