import React from 'react';
import './three-dots-loader.styles.scss';

const ThreeDotsLoader = () => {
    return (
        <div className='three-dots-loader'>
            <div className="loading">
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
            </div>
        </div>
    )
}

export default ThreeDotsLoader