import React from 'react';
import './three-dots-loader-small.styles.scss';

const ThreeDotsLoaderSmall = () => {
    return (
        <div className='three-dots-loader-small'>
            <div className="loading">
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
                <span className="loading__dot"></span>
            </div>
        </div>
    )
}

export default ThreeDotsLoaderSmall