import React, { useEffect, useState } from 'react';
import './eval-determination.styles.scss';

const EvalDetermination = ({ data }) => {
    
    const [ determination, setDetermination ] = useState('Not Approved');

    useEffect(() => {
        if (!data) return;
    
        if (data.approved || (data.located && data.explained && data.completed)) {
            setDetermination('Approved');
        } else {
            setDetermination('Not Approved');
        }
    }, [data])

    return (
        <div className='eval-determination'>
            <h4>Determination:</h4>
            <div className={determination}>
                {determination}
            </div>
        </div>
    )
}

export default EvalDetermination