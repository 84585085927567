import React, { useEffect, useRef, useState } from 'react'
import './outcome-modal.styles.scss';

import Modal, { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';

const OutcomeModal = ({ show, cancel, formData, saveData, edit, clearEdit }) => {

    const [ tempOutcome, setTempOutcome ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const dataRef = useRef();

    useEffect(() => {
        if (!edit) {return;}
        dataRef.current = formData.content[edit];
        setTempOutcome(dataRef.current);
    }, [edit])

    function saveEoData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setTempOutcome(dataRef.current);
    }

    function handleSubmit() {
        let newEos = {...formData.content};
        const id = `eo${tempOutcome.num}`;
        if (!tempOutcome.num || !tempOutcome.desc) {
            setAlertMessage('Please complete the form before moving on.');
            return;
        }
        if (newEos[id] && !edit) {
            setConfirmMessage(`This will overwrite the description for Essential Outcome #${tempOutcome.num}, but the components created will stay intact. Do you want to continue?`);
            return;
        } else {
            approveSubmit();
        }
        
    }

    async function approveSubmit() {
        let newEos = {...formData.content};
        const id = `eo${tempOutcome.num}`;
        newEos[id] = {
            'desc': tempOutcome.desc,
            'id': id,
            'name': `Essential Outcome #${tempOutcome.num}`,
            'num': tempOutcome.num,
            'comp': (newEos[id] && newEos[id].comp) ? newEos[id].comp : {},
        }
        console.log(newEos);
        saveData('content', newEos);
        clearForm();
    }

    function clearForm() {
        dataRef.current = {}
        setTempOutcome(dataRef.current);
        clearEdit();
        cancel();
    }

    return (
        <div className='outcome-modal'>
            <Modal show={show} cancel={cancel} text='Create/Edit an Essential Outcome' >
                <div className='field inline'>
                    <label>Essential Outcome #</label>
                    <input type='number' value={tempOutcome && tempOutcome.num ? tempOutcome.num : ''} onChange={(e) => saveEoData('num', e.target.value)}></input>
                </div>
                <div className='field'>
                    <label>Content</label>
                    <textarea rows="3" value={tempOutcome && tempOutcome.desc ? tempOutcome.desc : ''} onChange={e => saveEoData('desc', e.target.value)}></textarea>
                </div>
                <div className='buttons'>
                    <button className='g-button' onClick={() => clearForm()}>Cancel</button>
                    {
                        (loading) 
                        ?
                        <button className='g-button submit'><ThreeDotsLoader /></button>
                        :
                        <button className='g-button submit primary' onClick={() => handleSubmit()}>Save Outcome</button>
                    }
                </div>
            </Modal>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} onApprove={approveSubmit} message={confirmMessage} />
        </div>
    )
}

export default OutcomeModal