import React, { useEffect, useState } from 'react'
import './settings-container.styles.scss';
import SortCriteria from './sort-criteria.component';

const SettingsContainer = ({ sortCriteria }) => {

    useEffect(() => {
        // getSettingsData();

        return () => {

        }
    }, [])

    async function getSettingsData() {
        
    }

    return (
        <div className='settings-container'>
            <div className='settings-content'>
                <div className='section-title'>
                    Settings
                </div>

                <div className='g-list-item sort-criteria'>
                    <SortCriteria sortCriteria={sortCriteria} />
                </div>
            </div>
        </div>
    )
}

export default SettingsContainer