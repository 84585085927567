import React, { useEffect, useState } from 'react'
import './manage-users.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faChartArea, faChartBar, faChartPie, faMagnifyingGlass, faPen, faPlusCircle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { switchUserButtons } from './admin-users.component'
import { Link, useNavigate } from 'react-router-dom'

const ManageUsers = ({ users, sortId }) => {

    const [ filteredUsers, setFilteredUsers ] = useState([]);
    const [ pageTitle, setPageTitle ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        switchUserButtons('users')
    }, [])

    useEffect(() => {
        if (!users || !sortId) {return;}
        if (sortId === 'part') {
            const filtered = Object.values(users).filter(u => u.permiss_part)
            setFilteredUsers(filtered);    
            switchUserButtons('participants');
            setPageTitle('Manage Micro-Credential Participants');
        }
        else if (sortId === 'eval') {
            const filtered = Object.values(users).filter(u => u.permiss_eval)
            setFilteredUsers(filtered);    
            switchUserButtons('evaluators');
            setPageTitle('Manage Evaluators');
        } 
        else if (sortId === 'admin') {
            const filtered = Object.values(users).filter(u => u.permiss_admin)
            setFilteredUsers(filtered);    
            switchUserButtons('administrators');
            setPageTitle('Manage Platform Administrators');
        } 
        else {
            setFilteredUsers(Object.values(users));
            switchUserButtons('users');
            setPageTitle('Manage All Users')
        }
        
    }, [users, sortId])

    function searchPeople(str) {
        console.log(str);
        const test = str.toLowerCase();
        setFilteredUsers(Object.values(users).filter(u => u.fName.toLowerCase().includes(test) || u.lName.toLowerCase().includes(test) || u.email.toLowerCase().includes(test)))
    }

    return (
        <div className='manage-users'>
            <div className='section-title with-icon'>
                {pageTitle}
                <FontAwesomeIcon className='clickable icon' title='Create New User' icon={faPlusCircle} onClick={() => navigate('/admin/createuser')} />
            </div>
            <div className="g-space-1"></div>
            <div className='search-bar'>
                <div className='field'>
                    <input text='text' onChange={(e) => searchPeople(e.target.value)} />
                </div>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
            <div className='meta'>
                User Count: {filteredUsers.length}
            </div>
            {
                (users) &&
                <table className='g-table'>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Joined</td>
                            <td>Enrolled</td>
                            <td>Evaluating</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        filteredUsers.sort((a, b) => (a.lName.toLowerCase() > b.lName.toLowerCase()) ? 1 : (a.lName.toLowerCase() === b.lName.toLowerCase()) ? ((a.fName.toLowerCase() > b.fName.toLowerCase()) ? 1 : -1) : -1 )
                        .map((user, index) => (
                            (user.fName) && 
                            <tr key={index}>
                                <td>{user.fName ? `${user.lName}, ${user.fName}` : ''}</td>
                                <td>{user.email ?? ''}</td>
                                <td>{user.ts_joined ? format(new Date(Number(user.ts_joined)), "P") : 'Pending'}</td>
                                <td>{user.enrolled ?? 0}</td>
                                <td>{user.evaluating ?? 0}</td>
                                <td className='actions'>
                                    <Link to={`/admin/userdata/${user.cst_key}`}>
                                        <button className='g-button small-btn' title='View User Data'><FontAwesomeIcon icon={faChartPie}  /></button>
                                    </Link>
                                    <Link to={`/admin/edituser/${user.cst_key}`}>
                                        <button className='g-button small-btn' title='Edit User'><FontAwesomeIcon icon={faPen} /></button>
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}

export default ManageUsers