import React, { useRef, useState } from 'react'
import './sort-criteria.styles.scss';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPen, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';

const SortCriteria = ({ sortCriteria }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ removeLoading, setRemoveLoading ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const dataRef = useRef();
    const approveRef = useRef();

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    async function hideSortCriteria(st) {
        setConfirmMessage('Are you sure you want to hide this Sort Criteria? This will also hide this as an option when sorting MC Data.')
        approveRef.current = () => {
            continueHideSortCriteria(st);
        }

        async function continueHideSortCriteria(st) {
            setRemoveLoading(true);
            const tempObj = {...st, ...{'hideCriteria': true}}
            const res = await UseCloudFunction('saveSortCriteria', tempObj);
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again. (Error: ${res.error})`);
                return;
            }
            setRemoveLoading(false);
        }
    }

    async function saveCriteriaToDb() {
        const tempData = {...dataRef.current};
        if (sortCriteria) {
            const existingTitles = Object.values(sortCriteria);
            if (existingTitles.filter(t => t.sortTitle.toLowerCase() === tempData.sortTitle.toLowerCase()).length > 0) {
                setAlertMessage('Sorry, this title already exists. Try a different one.');
                return;
            }
        }
        setLoading(true);
        if (!tempData.sortTitle) return;
        tempData.ts = new Date().getTime();
        tempData.cst_key = userProfile.cst_key;
        const res = await UseCloudFunction('saveSortCriteria', tempData);
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
            return;
        }
        setLoading(false);
        dataRef.current = {};
        setFormData(dataRef.current);
    }

    return (
        <div className='sort-criteria'>
            <h3>New Sort Criteria</h3>
            <div className='field-with-button'>
                <div className='field'>
                    <label>Criteria Title</label>
                    <input type='text' value={formData.sortTitle ?? ''} onChange={e => saveData('sortTitle', e.target.value)} />
                </div>
                <div>
                    {
                        (loading) 
                        ?
                        <button className='g-button'><ThreeDotsLoader /></button>
                        :
                        <button className='g-button primary' onClick={() => saveCriteriaToDb()}>Save Criteria</button>
                    }
                </div>
            </div>
            <div className='created-list'>
                <h3>Created Criteron</h3>
                {
                    (sortCriteria) &&
                    Object.values(sortCriteria)
                    .sort((a,b) => a.sortTitle.toLowerCase() > b.sortTitle.toLowerCase() ? 1 : -1)
                    .filter(st => st.status === 'active')
                    .map(st => (
                        <div key={st.id} className='g-list-item'>
                            {st.sortTitle}
                            <div className='buttons'>
                                <button className='g-button small-btn'>
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                                {
                                    (removeLoading) 
                                    ?
                                    <button className='g-button small-btn'>
                                        <FontAwesomeIcon icon={faRefresh} className='icn-spinner' />
                                    </button>
                                    :
                                    <button className='g-button small-btn' onClick={() => hideSortCriteria(st)}>
                                        <FontAwesomeIcon icon={faBan} />
                                    </button>   
                                }
                            </div>    
                        </div>
                    ))    
                }
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(false)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
        </div>
    )
}

export default SortCriteria